import React, { useEffect, useState } from "react";
import { IonButton, IonLabel } from "@ionic/react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { userTabClick } from "../../../Events";

const MainDiv = styled.div`
  position: relative;
`;
const Li = styled.li`
  color: #80808069;
  margin-top: 2rem;
`;
const Div = styled.div`
  position: absolute;
  bottom: 2rem;
`;

const fotnColor: any = {
  color: "#3ab34a",
  fontWeight: "500",
};

const SideBar: React.FC = () => {
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const locationPathName = location.pathname;

  useEffect(() => {
    switch (locationPathName) {
      case "/home":
        userTabClick("/home");
        setActiveTab("/home");
        break;
      case "/additem":
        userTabClick("/additem");
        setActiveTab("/additem");
        break;
      case "/schedulefill":
        userTabClick("/schedulefill");
        setActiveTab("/schedulefill");
        break;
      case "/profile":
        userTabClick("/profile");
        setActiveTab("/profile");
        break;
      case "/pickup":
        userTabClick("/pickup");
        setActiveTab("/pickup");
        break;

      default:
        break;
    }
  }, [locationPathName]);
  var windowSize = window.outerWidth;
  if (windowSize > 767) {
    window.fcWidget && window.fcWidget.show();
  } else {
    if (window.location.pathname === "/support") {
      window.fcWidget && window.fcWidget.show();
    } else {
      window.fcWidget && window.fcWidget.hide();
    }
  }

  return (
    <MainDiv className="web_view_tab web_view_top ">
      <Link to="/schedulefill">
        <IonButton
          expand="block"
          className="btn-schedule-pickup"
          color="success"
        >
          Schedule a Pickup
        </IonButton>
      </Link>
      <ul>
        <Link to="/home">
          <li>
            <img
              src="https://ik.imagekit.io/y8s2vhk66ef/home_V_7qW55zh.png"
              alt=""
            />
            <h4 className={activeTab === "/home" ? "active_page" : ""}>Home</h4>
          </li>
        </Link>
        <Link to="/additem">
          <li>
            <img
              src="https://ik.imagekit.io/y8s2vhk66ef/rate_2_4BPdE8_.png"
              alt=""
            />
            <h4 className={activeTab === "/additem" ? "active_page" : ""}>
              Rates
            </h4>
          </li>
        </Link>
        <Link to="/pickup">
          <li>
            <img
              src="https://ik.imagekit.io/y8s2vhk66ef/pickup_car_n26GYfrV0.png"
              alt=""
            />
            <h4 className={activeTab === "/pickup" ? "active_page" : ""}>
              My Pickups
            </h4>
          </li>
        </Link>

        {/* <Link to="/home">
                    <li >
                        <img src={Setting} alt="" />
                        <h4>Settings</h4>
                    </li>

                </Link> */}
        <Link to="/profile">
          <li>
            <img
              src="https://ik.imagekit.io/y8s2vhk66ef/profile_black_tF5M2vYci.png"
              alt=""
            />
            <h4 className={activeTab === "/profile" ? "active_page" : ""}>
              Profile
            </h4>{" "}
          </li>
        </Link>

        <Li>version 2.0.1</Li>
      </ul>
      <Div>
        <img
          src="https://ik.imagekit.io/y8s2vhk66ef/google-play-badge_I1PRpLQ5_.png"
          alt=""
        />
      </Div>
    </MainDiv>
  );
};

export default SideBar;
