/**
 *  User Events Function.....
 */
const windowFcWidget =(event:any)=> {
  window.fcWidget &&
  window.fcWidget.track(`${event}`, {
    //Key and value can be anything relatable to your web app.
    cartValue: 5000,
    lastItemCategory: "electronics",
  });
}

export const userTabClick = (event: any) => {
  //After Page and Widget is Loaded.
  //Check if widget is loaded using the isLoaded Condition
  //Event name add_to_cart can also be set to your preference.
  windowFcWidget(event)
};

export const schedulePickUpSuccessuflly = (event: any) => {
  console.log(`${event}`);
  windowFcWidget(event)
};
export const userEditProfile = (event: any) => {
  console.log(`User Edit Profile ${event}`);
  windowFcWidget(event)
};
export const userIsNow = (event: any) => {
  console.log(`User is now ${event}`);
  windowFcWidget(event)
};
