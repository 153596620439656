import {
  IonCol,
  IonButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonIcon,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./LoginPage.css";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { arrowBackOutline } from "ionicons/icons";
import ApiRequest from "../../Services/Api.Service";
import { useHistory } from "react-router";
import useLocalStorage from "../../Services/useLocalStorage";
import { UserProfile } from "../../App";
import SignupDetailsWeb from "../SignUPOTP/SignupDetailsWeb";

const Div = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3%;
`;
type Inputs = {
  example: string;
  exampleRequired: string;
  firstName: string;
  lastName: string;
  email: string;
  isDeveloper: boolean;
};

const LoginPageWebView: React.FC = () => {
  const [phoneStatus, setPhoneStatus] = useState<any>("phone");
  const { register, handleSubmit } = useForm<Inputs>();
  const [loadingSpin, setLoadingSping] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActivePromo, setIsActivePromo] = useState(false);
  const [isActiveOtp, setIsActiveOtp] = useState(false);
  const [values, setValues] = useState<any>(null);
  const history = useHistory();
  const [error, setError] = useState({ status: false, message: "" });
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [inValidOtp, setInValidOtp] = useState({ status: false, message: "" });
  const [phoneNumberLength, setPhoneNumberLength] = useState<any>(0);

  const [otpNumberLength, setOtpNumberLength] = useState<any>(0);
  const [minutes, setMinutes] = useState<any>(1);
  const [seconds, setSeconds] = useState<any>(0);
  const [timeOut, setTimeOut] = useState<any>(false);
  const [resendCodeStop, setResendCodeStop] = useState<any>(0);
  const [promoCode, setPromoCode] = useState<any>(false);

  const promoCodeChange = (text: any) => {
    if (text !== "") {
      setError({ status: false, message: "" });
      setValues(text);
      setIsActivePromo(true);
    } else {
      setIsActivePromo(false);
    }
  };
  const handleTextChange = (text: any) => {
    const val = text;
    const valString = val.toString();
    const max = 10;

    if (valString.length <= max) {
      setPhoneNumberLength(Number(val));
    }

    if (text !== "") {
      setError({ status: false, message: "" });
      setValues(text);
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  const handleChangeOtp = (text: any) => {
    const val = text;
    const valString = val.toString();
    const max = 4;

    if (valString.length <= max) {
      setOtpNumberLength(Number(val));
    }

    if (text !== "") {
      setIsActiveOtp(true);
    } else {
      setIsActiveOtp(false);
    }
  };
  const onSubmit = (data: any) => {
    const phoneNumber = { phone: data.phone };
    const phoneCode = { phone: phoneNumberLength.toString(), code: data.otp };

    if (phoneStatus === "phone") {
      if (values.length > 9) {
        setLoadingSping(true);
        ApiRequest("POST", "auth/send", { body: phoneNumber }).then((data) => {
          if (data.success) {
            setLoadingSping(false);
            setPhoneStatus("otp");
          } else {
            setError({ status: true, message: data.message });
            setLoadingSping(false);
          }
        });
      } else {
        setError({
          status: true,
          message: "Enter a valid Phone Number. Number should be 10 digit!",
        });
        setLoadingSping(false);
      }
    } else if (phoneStatus === "otp") {
      setLoadingSping(true);
      ApiRequest("POST", "/auth/verify", { body: phoneCode }).then((data) => {
        if (data.success === true) {
          ApiRequest("GET", "/profile/", {
            isAuthenticated: false,
            token: data.token,
          }).then((resData) => {
            console.log({loggedInUser,resData})
            if (resData.success === true) {
              const dataInfo = resData.data[0];
              setLoggedInUser({ ...loggedInUser, ...dataInfo, ...data });
              setProfile({ ...profile, ...dataInfo, ...data });
              setLoadingSping(false);
              setInValidOtp({ status: false, message: "" });
              setTimeout(() => {
                if (data.first_login) {
                  setPhoneStatus("first_login");
                } else {
                  history.push("/home");
                  setLoadingSping(false);
                }
              }, 1200);
            }
          });
        } else {
          setInValidOtp({ status: false, message: "" });
          setLoadingSping(false);
        }
      });
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds === 1 && minutes === 0) {
        setTimeOut(true);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [phoneStatus]);

  const resendCode = () => {
    setMinutes(1);
    setResendCodeStop(resendCodeStop + 1);
    setTimeOut(false);
    const phoneOtp = { phone: phoneNumberLength.toString() };

    ApiRequest("POST", "/auth/re-send", { body: phoneOtp }).then((data) =>
      console.log(data)
    );
  };
  return (
    <Div>
      <div className="design_box">
        <div className="logo_image">
          <img
            className="logo"
            src="https://ik.imagekit.io/y8s2vhk66ef/swapeco_logo1_TZJf5I_fjY.png"
            alt=""
          />
          <img
            className="middle_image"
            src="https://ik.imagekit.io/y8s2vhk66ef/homeMiddle_Asp0dqEK2.png"
            alt=""
          />
        </div>
        <IonGrid>
          <IonRow>
            <IonCol className="title_box">
              <h3 className="welcome_page_title">Welcome to Scrapuncle</h3>
              <p className="welcome_page_subtitle">
                India's first digital platform to sell recyclables
              </p>
              <ul className="welcome_page_ul">
                <li>papers</li>
                <li>plastics</li>
                <li>metals</li>
                <li>ewaste</li>
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <div className="content_box_login">
        {phoneStatus === "first_login" ? (
          <SignupDetailsWeb setPhoneStatus={setPhoneStatus} />
        ) : (
          <>
            <div className="login_box">
              {phoneStatus === "otp" && (
                <IonIcon
                  className="icon_back"
                  onClick={() => setPhoneStatus("phone")}
                  icon={arrowBackOutline}
                ></IonIcon>
              )}
              <IonIcon></IonIcon>
              <h1 className="login_title">
                {phoneStatus === "otp" ? "Enter OTP" : "Login"}
              </h1>
            </div>

            <p className="or_create_account">
              {phoneStatus === "otp"
                ? "We've sent an OTP to your phone number."
                : ""}{" "}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="webview_number_input_box">
                <div id="float-label">
                  {
                    <input
                      ref={register}
                      className="input_text"
                      value={
                        phoneNumberLength === 0 ? false : phoneNumberLength
                      }
                      disabled={phoneStatus === "otp" ? true : false}
                      type="number"
                      maxLength={10}
                      onChange={(e) => handleTextChange(e.target.value)}
                      name="phone"
                      autoComplete="number"
                      required
                    />
                  }

                  <label
                    className={isActive ? "Active label_text" : "label_text"}
                    htmlFor="email"
                  >
                    Phone Number
                  </label>
                </div>
              </div>

              {phoneStatus === "phone" && (
                <p
                  className="sign_resend_code mb-1 cursor-pointer"
                  onClick={() => setPromoCode(true)}
                >
                  Promo Code
                </p>
              )}
              {promoCode && (
                <div id="float-label">
                  {
                    <input
                      ref={register}
                      className="input_text"
                      disabled={phoneStatus === "otp" ? true : false}
                      type="text"
                      maxLength={10}
                      onChange={(e) => promoCodeChange(e.target.value)}
                      name="promo_code"
                      required
                    />
                  }
                  <label
                    className={
                      isActivePromo ? "Active label_text" : "label_text"
                    }
                    htmlFor="email"
                  >
                    Promo Code
                  </label>
                  {phoneStatus !== "otp" && (
                    <p
                      onClick={() => {
                        setPromoCode(false);
                        setIsActivePromo(false);
                      }}
                      className="sign_resend_code invalid"
                    >
                      NOT NOW!
                    </p>
                  )}
                </div>
              )}

              {error.status && (
                <p className="sign_resend_code invalid">{error.message}</p>
              )}

              {phoneStatus === "otp" && (
                <>
                  <div className="webview_number_input_box last_child">
                    <div id="float-label">
                      <input
                        value={otpNumberLength === 0 ? false : otpNumberLength}
                        ref={register}
                        type="number"
                        maxLength={5}
                        className="input_text"
                        onChange={(e) => handleChangeOtp(e.target.value)}
                        name="otp"
                        required
                      />

                      <label
                        className={
                          isActiveOtp ? "Active label_text" : "label_text"
                        }
                        htmlFor="email"
                      >
                        Enter Otp
                      </label>
                    </div>
                  </div>

                  {resendCodeStop === 3 ? (
                    <p className="check_inbox">
                      {/* Check your Inbox! */} Enter 9999 for success.
                    </p>
                  ) : timeOut ? (
                    <p onClick={resendCode} className="sign_resend_code resend">
                      Resend Code
                    </p>
                  ) : (
                    <p className="sign_resend_code">
                      No code? Resend after
                      {minutes === 0 && seconds === 0 ? null : (
                        <>
                          {" "}
                          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </>
                      )}
                    </p>
                  )}

                  {inValidOtp.status && (
                    <p className="sign_resend_code invalid">
                      {inValidOtp.message}
                    </p>
                  )}
                </>
              )}

              <IonButton
                type="submit"
                color="success"
                className="login_btn_web_view"
              >
                {loadingSpin ? (
                  <IonSpinner name="lines" />
                ) : phoneStatus === "otp" ? (
                  "Enter OTP"
                ) : (
                  "LOGIN"
                )}
              </IonButton>
            </form>
          </>
        )}
      </div>
    </Div>
  );
};

export default LoginPageWebView;
