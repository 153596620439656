import React, { useContext, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { UserProfile } from "../../App";
import useLocalStorage from "../../Services/useLocalStorage";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  computedMatch?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const [profile] = useContext<any>(UserProfile);
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const { component: Component,  ...rest } = props;

  useEffect(()=>{
    console.log("Current Propos:", props)
  },[])

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        (loggedInUser.phone && loggedInUser.phone.length !== 0) ||
        (profile.phone && profile.phone.length !== 0) ? (
          <Component {...routeProps} match={props.computedMatch || 'hello'}/>
        ) : (
          <Redirect
            to={{
              pathname: "/login-page",
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
