import { API_CONFIG } from "Config";

export default function ApiRequest(
  Method: any,
  Path: string,
  options: any = {}
) {
  let Base_url = API_CONFIG.BASE_URL;
  let fetchOptions: any = {
    method: Method,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Accept: "application/json",
    },
  };

  if(options && options.source){
    fetchOptions.headers['x-source'] = options.source
  }

  if (options && options.isAuthenticated === false) {
    fetchOptions.headers["x-w3dev-token"] = `${options.token}`;
  }

  if (Method === "POST" || Method === "PUT") {
    fetchOptions.body = JSON.stringify(options.body);
  }

  return fetch(`${Base_url}/${Path}`, fetchOptions).then((res) => res.json());
}

// isAuthenticated,
// piRequest("GET",/o"")
