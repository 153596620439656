import { IonCol, IonContent, IonGrid, IonHeader, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SchedulePickUp } from "../../../App";
import "./UpcomingPickupDetails2.css";
import ApiRequest from "../../../Services/Api.Service";
import useLocalStorage from "../../../Services/useLocalStorage";
import dayjs from "dayjs";

const H3 = styled.h3`
  text-transform: uppercase;
`;
const P = styled.p`
  justify-self: center;
`;

interface PropsDetails {
  header: boolean;
  match ?: any;
}

const UpcomingPickupDetails2: React.FC<PropsDetails> = (props) => {
  const { header } = props
  const [schedule, setSchedule] = useContext<any>(SchedulePickUp);
  const [upcomingData, setUpcomingData] = useState<any>({});
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );

  useEffect(() => {

    console.log("Looking for:",props?.match?.params?.id)
    let pickupId = props?.match?.params?.id;

    // Set Latest Pickup ID from all Pending Orders
    if(!pickupId){
      const requestFilter = encodeURIComponent(`status=pending`)
      ApiRequest("GET", `pickup/all?filter=${requestFilter}`, {
        isAuthenticated: false,
        token: loggedInUser.token,
      }).then((data) => setUpcomingData(data.data.slice(-1)[0]));
    }
    // Query via Pickup ID
    else{

    }
    
  }, []);

  const month_name = function (dt: any) {
    const mlist: any = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return mlist[dt];
  };
  const dayName = (daycount: any) => {
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    return weekdays[daycount];
  };

  return (
    <>
      {header && (
        <IonHeader id="ion_header" className="d-flex-pickupdetailsss">
          <Link to="/schedulefill">
            <div className="d-flex-pickupdetails">
              <img
                src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
                alt=""
              />
              <P>Back</P>
            </div>
          </Link>

          <Link to="/support">
            {" "}
            <p className="header_p">Supports</p>
          </Link>
        </IonHeader>
      )}
      <IonContent>
        <IonGrid style={{padding:"20px"}}>
          <IonRow>
            <IonCol>
              <h3 className="upcoming_pickup_detail_title">Pickup Summary</h3>
              <p className="upcoming-details">
                {upcomingData.pickup_agent && upcomingData.pickup_agent.status === "ASSIGNED" ? <>
                This pickup is assigned to Recyling Hero/Star:
                {upcomingData.pickup_agent &&
                upcomingData.pickup_agent.status === "ASSIGNED"
                  ? upcomingData.pickup_agent.details.name
                  : upcomingData.pickup_agent &&
                    upcomingData.pickup_agent.status}
                {upcomingData.pickup_agent &&
                upcomingData.pickup_agent.status === "ASSIGNED" ? (
                  <img
                    alt="pickup_agent"
                    src={upcomingData.pickup_agent.details.photo}
                  />
                ) : null}
                </>:<>
                  Recycling Here will be assigned soon!
                </>}
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <H3 className="text-threePage">Pickup Products</H3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className="blockTwoPage ">
                {upcomingData.items &&
                  upcomingData.items.map((item: any) => (
                    <div key={item.id} className="block_box  d-nones">
                      
                        <img src={item.image} alt={"icon"} />
                      
                      <div className="title_rate">
                        <p>{item.title}</p>
                        <p>
                          RS {item.rate.amount}/{item.rate.unit}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <H3 className="text-threePage">Pickup Details</H3>
              <p className="payment_title">order Number</p>
              <p className="payment-details">3268226</p>

              <p className="payment_title">payment method</p>
              <p className="payment-details">Cash on Delivery</p>

              <p className="payment_title">Date and time</p>
              <p className="payment-details">
                {dayName(
                  dayjs(upcomingData && upcomingData.pickup_date)
                    .toDate()
                    .getDay()
                )}
                ,
                {month_name(
                  dayjs(upcomingData && upcomingData.pickup_date)
                    .toDate()
                    .getMonth()
                )}
                {` ${dayjs(upcomingData.pickup_date).toDate().getDate()} `}
                AT{" "}
                {upcomingData.pickup_time &&
                  upcomingData.pickup_time.slice(0, 15)}{" "}
              </p>

              <p className="payment_title">Pickup Address</p>
              <p className="payment-details">
                {upcomingData.address && upcomingData.address.line1}{" "}
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default UpcomingPickupDetails2;
