import React from "react";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import PickupComplete from "Components/LocalHome/PickupComplete/PickupComplete";
import LocalPickupView from "Components/LocalHome/LocalPickupView/LocalPickupView";
import LocalSchedule from "Components/LocalHome/LocalSchedule/LocalSchedule";
import LocalRate from "Components/LocalHome/LocalRate/LocalRate";
import LocalHome from "Components/LocalHome/LocalHome";
import "./WebMode.css"

const WebMode = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/local-pickup-complete" component={PickupComplete} />
        <Route path="/local-pickup-view" component={LocalPickupView} />
        <Route path="/local-schedule-pickup" component={LocalSchedule} />
        <Route path="/local-rate" component={LocalRate} />
        <Route path="/home" component={LocalHome} />
        <Route path="/" exact component={LocalHome} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default WebMode;
