import React, { useContext, useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import "./HeaderLocal.css";
import { personCircleOutline, searchOutline } from "ionicons/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import useLocalStorage from "Services/useLocalStorage";
import { UserProfile } from "App";

interface ContainerProps {
  show: any,
  setFilterData:any,
  
}
const HeaderLocal: React.FC<ContainerProps> = ({ show,setFilterData }) => {
  const [profile] = useContext<any>(UserProfile);
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [activeTab, setActiveTab] = useState('local-home')
  const history = useHistory();
  const {pathname} = useLocation();
  useEffect(() => {
    setActiveTab(history.location.pathname)
  }, [])

  const clickWhyUs =()=>{
    if(pathname === '/'){
      history.push({
        pathname: '/home',
        state: 'whyus'
      })
    }else{
      history.push({
        pathname: '/',
        state: 'whyus'
      })
    }
  }
  return (

    <div className="localHeader_main ">
      <div className="container header_main">

        <nav className="navbar navbar-expand-lg navbar-light bg-light w-100">

          <Link className="navbar-brand" to="/home">
            <img className="local_header_logo"
              src="https://ik.imagekit.io/h1rxzpffx/scrapuncle/ScrapUncleNewLogo_LimmzWp0YWe.png"
              alt="Scrapuncle - Recycling Made Easy"
            />
            {/* <img className="local_header_logo"
              src="https://ik.imagekit.io/y8s2vhk66ef/swapeco_logo1_TZJf5I_fjY.png?updatedAt=1624716592296"
              alt="Swapeco Logo"
            /> */}
          </Link>
{
  show.ul &&
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
}

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {
              show.ul &&
              <ul className="header_ul navbar-nav">
                <Link to='/home'>
                  <li className={activeTab === '/home' || activeTab === '/'  ? 'active nav-item' : ' nav-item'}>Home</li>
                </Link>
               
                  <li onClick={clickWhyUs} className={activeTab === '/local-why' ? 'active nav-item' : ' nav-item'}>Why Us?</li>
             
    
              
                <Link to='/local-rate'> <li className={activeTab === '/local-rate' ? 'active' : ''}>Scrap Rates</li>
                </Link>
              </ul>
            }

            {
              show.profile &&
              <div className="profileName_pic">
                <p>{profile.name || loggedInUser.name}</p>
                <IonIcon className="person_circle_icon" icon={personCircleOutline} />
              </div>
            }
            {
              show.search &&
              <div className='local_header_search'>
                <label htmlFor="search_input">
                  <IonIcon className='search_outline_icon' icon={searchOutline} />
                </label>
              
                <input id='search_input' onChange={(e) => setFilterData(e.target.value)} type='text' placeholder='Search an item' />

              </div>
            }

            {
              show.location &&
              <div className="local_location hidden">
                <img
                  className="location_pin_icon"
                  src="https://ik.imagekit.io/y8s2vhk66ef/Icon_location_outline_QwOdadb18.png?updatedAt=1628056486097"
                  alt="location pin"
                />
                <input
                  className="search_input_local"
                  type="text"
                  placeholder="Enter your Location"
                />

                <ul className="location_dropdown_ul ">
                  <li className="dropdown_current_location">
                    <img
                      src="https://ik.imagekit.io/y8s2vhk66ef/Locate_Icon_jNWrX7x5f.png?updatedAt=1628061490734"
                      alt="current location"
                    />
                    <span> Current Location</span>
                  </li>
                  <li className="dropdown_search_location">
                    <img
                      src="https://ik.imagekit.io/y8s2vhk66ef/Icon_location_outline_QwOdadb18.png?updatedAt=1628056486097"
                      alt=""
                    />
                    <span> 123, Mehrauli, Delhi </span>
                  </li>
                </ul>
              </div>
            }

          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderLocal;
