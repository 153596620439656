import React, { useState } from "react";
import { IonButton, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import "./MainContent.css";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

interface ContainerProps {
  name: string;
  homeContentInfo: any;
}

const MainContent: React.FC<ContainerProps> = ({ name, homeContentInfo }) => {
  const [referBox, setReferBox] = useState(true);

  return (
    <div className="container">
      <Link to="/scheduleFill">
        <IonButton className="schedule_btn" color="success" expand="block">
          Schedule a pickup
        </IonButton>
      </Link>
      <IonGrid>
        <div className="block" style={{margin:"0px"}}>
          <div className="home_sliders">
            {homeContentInfo.sliders && homeContentInfo.sliders.length > 0 && homeContentInfo.sliders.map((slider:any, i:number)=>(
              <div key={i} className="home_slider">
                <img src={slider.src} />
              </div>
            ))}
          </div>
        </div>
      </IonGrid>
      {homeContentInfo.upcoming_pickup && (
        <h3 className="text">Upcoming Pickup</h3>
      )}

      <IonGrid>
        {homeContentInfo.upcoming_pickup && (
          <IonRow>
            <IonCol>
              <div className="d-flex page_three_box">
                <div className="date">
                  <h3>
                    {dayjs(homeContentInfo.upcoming_pickup.pickup_date)
                      .toDate()
                      .toString()
                      .slice(0, 3)}
                  </h3>

                  <b>
                    {dayjs(homeContentInfo.upcoming_pickup.pickup_date).date() <
                      10 && "0"}
                    {dayjs(homeContentInfo.upcoming_pickup.pickup_date).date()}
                  </b>
                </div>

                <div className="location">
                  <p>
                    {homeContentInfo.upcoming_pickup &&
                      homeContentInfo.upcoming_pickup.pickup_time.slice(0, 8)}
                  </p>
                  <p>{homeContentInfo.upcoming_pickup.address.line1}</p>
                  <span>
                    {homeContentInfo.upcoming_pickup.items[0].title},
                    {homeContentInfo.upcoming_pickup &&
                      homeContentInfo.upcoming_pickup.items.length > 1 &&
                      " +"}
                  </span>
                  <span>
                    {homeContentInfo.upcoming_pickup &&
                      homeContentInfo.upcoming_pickup.items.length + " MORE"}
                  </span>
                </div>
              </div>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      <h3 className="text mt-3">Impact by Scrapuncle</h3>
      <IonGrid>
        <IonRow>
          <IonCol>
            <div className="impact_div">
              <IonImg
                className="impact_img"
                src="https://ik.imagekit.io/y8s2vhk66ef/Family_Values_Outdoors_1__1__7c0ij8cNTWO.png?updatedAt=1632012907722"
                alt=""
              />
              <div className="impact_img_text">
                <div className="">
                  <h3>
                    {homeContentInfo.impact && homeContentInfo.impact.recycled}
                  </h3>
                  <p>recycled</p>
                </div>
                {/* <div className="">
                  <h3>
                    {homeContentInfo.impact && homeContentInfo.impact.earned}
                  </h3>
                  <p>earned</p>
                </div> */}
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <div onClick={() => setReferBox(false)} className="refer_box">
        {referBox && (
          <div className="refer_friend">
            <Link className="referImage" to="/referfriend">
              <IonImg
                src="https://ik.imagekit.io/y8s2vhk66ef/Union_3__cOndaa-Te4.png"
                alt="share"
              />
            </Link>
            <Link className="refertext" to="/referfriend">
              <h3 id="text_white">Refer a friend to earn</h3>
            </Link>
            <IonImg
              className="refer_close"
              src="https://ik.imagekit.io/y8s2vhk66ef/Subtract_2__tNGwPBh0h.png"
              alt="closeIcon"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainContent;
