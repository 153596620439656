import { IonIcon } from "@ionic/react";
import { Link } from "react-router-dom";
import { checkmarkCircleOutline } from "ionicons/icons";
import React, { useContext, useState } from "react";
import HeaderLocal from '../../Header/HeaderLocal';
import "./PickupComplete.css";
import useLocalStorage from "Services/useLocalStorage";
import { UserProfile } from "App";

const PickupComplete: React.FC = () => {
  const [filterDate, setFilterData] = useState("");
  const [profile ] = useContext<any>(UserProfile);
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  
  return (
    <>
    <HeaderLocal setFilterData={setFilterData} show={{ ul: false, location: false, search: false, profile: true }} />
    <div className="local_pickup_complete">
      
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12">
            <h1 className='pickup_complete_title'>Yay! Your pick up has been scheduled. <IonIcon className='checkmark_' icon={checkmarkCircleOutline} /></h1>
            <p className='pickup_complete_text'>We will call you soon to confirm {(profile && profile.name) || (loggedInUser && loggedInUser.name)}</p>
            <Link to='/local-pickup-view'>
              <button className='pickup_complete_button'>VIEW PICKUPS</button>
            </Link>
            <br />
            <Link to='/local-schedule-pickup'>
              <button className='pickup_complete_button'>SCHEDULE ANOTHER PICKUP</button>
            </Link>
          </div>
        </div>
      </div>

    </div>
  </>
  );
};

export default PickupComplete;
