import React, { useEffect } from "react";
import { IonContent, IonHeader, IonRow, IonCol, IonGrid } from "@ionic/react";
import "./Support.css";
import { useHistory } from "react-router-dom";
import Title from "../Title";
import { userTabClick } from "../../Events";

declare global {
  interface Window {
    fcWidget: any;
  }
  interface Window {
    attachEvent: any;
    fcWidget: any;
  }
}
interface ContainerProps {
  Header: boolean;
}
const Support: React.FC<ContainerProps> = (props) => {

  const history = useHistory();
  
  useEffect(() => {
    
    userTabClick(window.location.pathname);
    let phone:any = null, email:any = null, fname:any = null, lname:any = null
    try{
      const loggedInUser:any = JSON.stringify(localStorage.getItem('LoginUser'))
      const name = loggedInUser['name'] || null
      phone = loggedInUser['phone'] || null
      email = loggedInUser['email'] || null
      if(name && name.length > 2){
        [fname, lname] = name.split(" ")
      }      
    }catch(e){
      console.error("Error Setting up Freshdesk Details")
    }
    
    window.fcWidget.init({

      config: {
        cssNames: {
          // widget: "hide",
          "expanded": "custom_fc_expanded",
          "widget": "custom_fc_frame"  
        },
      },
      open: true,
      token: "f66a2b75-2773-47fa-97d5-4f162c791394",
      host: "https://wchat.freshchat.com",

      onInit: function(){

        window.fcWidget.on('widget:loaded', function () { 
          
          window.fcWidget.user.get(function (resp:any) {
            const status = resp && resp.status;
            if (status !== 200) {

              const fcProps:any = {
                phone: phone,
                phoneCountryCode: "+91"
              }

              if(fname) fcProps.firstName = fname
              if(lname) fcProps.lastName = lname
              if(email) fcProps.email = email

              window.fcWidget.user.setProperties(fcProps);
            }
          });

        })

      },
    });

    return () => {
      console.log('HIDING_FRESHCHAT')
      window.fcWidget.init({
        config:{
          headerProperty:{
            hideChatButton: true
          }
        },
        token: "f66a2b75-2773-47fa-97d5-4f162c791394",
        host: "https://wchat.freshchat.com",
      })
    }

  }, []);

  return (
    <>
      <IonHeader id="ion_header" className="d-flex-pickupdetails">
        <img
          onClick={() => history.goBack()}
          src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
          alt=""
        />
        <p onClick={() => history.goBack()}>Back</p>
      </IonHeader>

      <IonContent>
        <Title title="ScrapUncle Support" />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="image_div">
                <img
                  className="mt-2"
                  src="https://ik.imagekit.io/y8s2vhk66ef/support_IBWayLKCj.svg"
                  alt="supportImage"
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="mb-5">
              <p className="description font-size-10 mt-4">
                ScrapUncle is always active in supporting their customers.
                <br /> Feel free to ask if you need any information about
                ScrapUncle.
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p className="description font-size-10 mb-4 ml-3">
                Click the chat icon and send sms.
                <br />
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Support;
