import React, { useState, useEffect, useContext, useRef } from "react";
import HeaderLocal from "Components/Header/HeaderLocal";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import useLocalStorage from "../../../Services/useLocalStorage";
import "./LocalSchedule.css";
import { IonButton, IonIcon, IonSpinner, IonToast } from "@ionic/react";
import {
  calendar,
  chevronDown,
  cloudUpload,
  helpCircle,
  location,
  repeat,
  time,
  trashOutline,
} from "ionicons/icons";
import "react-calendar/dist/Calendar.css";
import { Accordion } from "react-bootstrap";
import { SchedulePickUp, UserContext, UseReload } from "App";
import ApiRequest from "../../../Services/Api.Service";
import MultipleImageUpload from "Components/ScheduleFill/MultipleImageUpload";
import uploads from "Components/ScheduleFill/uploads";
import { useHistory } from "react-router";
import LocationSidebar from "Components/WebView/WebHeader/LocationSidebar";

const TIME_OPTIONS = [
  {
    label: '11 AM - 3 PM',
    value: '11-3pm'
  },
  {
    label: '3 PM - 8 PM',
    value: '3-8pm'
  },
]

const WEIGHT_RANGE = [
  {
    label: 'Less than 20 Kg',
    value: 0,
  },
  {
    label: '20-50 Kg',
    value: 1,
  },
  {
    label: '50-100 Kg',
    value: 2,
  },
  {
    label: '100-700 Kg',
    value: 3,
  },
  {
    label: 'More than 700 Kg',
    value: 4,
  },
]

const LocalSchedule: React.FC = () => {
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const [schedule, setSchedule] = useContext<any>(SchedulePickUp);
  const [reload, setReload] = useContext<any>(UseReload);
  let tomorrowDate: Date = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)
  const [value, onChange] = useState<any>(tomorrowDate);
  const [address, setAddress] = useState([]);
  const [file, setFile] = useState<any>([]);
  const [LoadingSpinner, setLoadingSpinner] = useState(false);
  const [showAlert1, setShowAlert1] = useState<any>({
    status: false,
    message: "",
  });
  const [uploadPicLink, setUploadPicLink] = useState<any>([]);
  const [filterDate, setFilterData] = useState("");
  const [timeDate, setTimeDate] = useState<any>({ time: {}, date: '' })
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const locationRef = useRef<any>(null);
  const [showItem, setShowItem] = useState<any>("map");
  const [position, setPosition] = useState();
  const getLocation = async () => { };
  const [selectObject, setSelectObject] = useState<any>({
    date: {},
    time: { title: "" },
    address: {},
    repeat: { title: "None", number: 1 },
  });
  const history = useHistory();
  useEffect(() => {

    if(!loggedInUser){
      history.push("/");
    }

    ApiRequest("GET", "/address/", {
      isAuthenticated: false,
      token: loggedInUser.token,
      source: 'web',
    })
    .then((data) => {
      setAddress(data.data)
      if(data.data?.length > 0)
      setSelectObject({...selectObject, address: data.data[0]})
    })
    .catch(e =>  history.push("/"))

  }, [reload.address]);

  const timeDetails = (times: string) => {
    document.querySelectorAll(".active_time").forEach(e=>e.classList.remove("active_time"))
    setTimeDate({ date: timeDate.date, time: { title: times, day: selectObject.time.day } })

  };

  const selectAddress = (item: string) => {
    setSelectObject({ ...selectObject, address: item });
  };

  const repeatChange = (e: any) => {
    setSelectObject({
      ...selectObject,
      repeat: { title: e.target.value, number: 1 },
    });
  };

  const validaeWeight = (e: any) => {
    let selected_value = e.target.value;
    if (selected_value > 0) {
      setSelectObject({
        ...selectObject,
        weight: { value: selected_value },
      });
    }
  }

  // Schedule Button CLick
  const scheduleClick = (e: any) => {
    console.log("click schedule");
    setLoadingSpinner(true);

    if (file.length) {
      //File Uploading on Imakekit server and get url
      var addMoreItems: any = document.getElementById("addMoreItem");
      const fileArry = [...addMoreItems?.files];
      var resultUrl: any = [];
      fileArry.map((item) =>
        uploads(e).upload(
          {
            file: item,
            fileName: item.name || "sample-file.jpg",
            tags: ["tag1"], // Comma seperated value of tags
            responseFields: "tags", // Comma seperated values of fields you want in response e.g. tags, isPrivateFile etc
          },
          (err: any, result: any) => {
            if (err) {
              setLoadingSpinner(false);
            } else {
              resultUrl = [...resultUrl, result.url];
              fileUploadLink();
            }
          }
        )
      );
      console.log({ resultUrl });

      const fileUploadLink = () => {
        if (resultUrl.length === file.length) {
          console.log("file upload complete");

          setUploadPicLink(resultUrl);
          setLoadingSpinner(true);
          setTimeout(() => {
            formSubmit(resultUrl);
          }, 1400);
        }
      };
    } else {
      setLoadingSpinner(true);
      setTimeout(() => {
        let resultEmpty: any = [];
        formSubmit(resultEmpty);
      }, 1400);
    }
  };

  const formSubmit = (resultUrl: any) => {
    console.log("form SUbmit");
    const formData = {
      ...schedule,
      ...selectObject,
      items: cartItems,
      photoes: resultUrl,
      message: "",
    };

    if (!formData.weight) return setShowAlert1({ status: false, message: 'Enter a valid Weight' })

    // // UNcomment for testing
    // return (()=>{
    //   console.log('Submit;', formData)
    //   setLoadingSpinner(false);
    // })();

    const apiPost = (formData: any) => {
      ApiRequest("POST", "pickup/new", {
        isAuthenticated: false,
        token: loggedInUser.token,
        body: formData,
        source: 'web',
      })
        .then((res) => {
          console.log({ resPost: res });
          if (res.success) {
            setLoadingSpinner(false);
            setShowAlert1({ status: true, message: res.message });
            setSchedule({
              address: {},
              items: [],
              date: {},
              repeat: { title: "week", number: 2 },
              time: "",
            });
            setCartItems([]);
            setReload({ ...reload, schedule: !reload.schedule });
            setTimeout(() => {
              history.push("/local-pickup-complete");
            }, 2000);
          } else {
            setShowAlert1({ status: true, message: res.message });
            setLoadingSpinner(false);
          }
        })
        .catch((err) => {
          console.log({ err });
          setShowAlert1({ status: true, massage: err.message });
          setLoadingSpinner(false);
        });
    };

    console.log({ formData });
    apiPost(formData);
    setLoadingSpinner(true);

    setTimeout(() => {
      setSchedule({
        address: {},
        items: [],
        date: {},
        repeat: { title: "week", number: 2 },
        time: "",
        schedule: schedule,
      });
    }, 1400);
  };

  const selectTime = () => {
    if (JSON.stringify(timeDate.time) !== '{}') {
      setSelectObject({
        ...selectObject,
        time: timeDate.time,
      });
      setTimeDate({ date: timeDate.date, time: {} })
    }

  }
  const selectDate = () => {
    let date = value.getDate();
    let month = value.getMonth() + 1;
    let year = value.getFullYear();

    let fullDate = { year: year, month: month, date: date };
    // const date = value.getDate();
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var dayName = days[value.getDay()];

    setSelectObject({
      ...selectObject,
      date: fullDate,
      time: { title: selectObject.time.title, day: dayName },
    });
    setTimeDate({ date: '', time: timeDate.time })

  }
  const addressClick = () => {
    // const latLng = JSON.parse(loggedInUser.defaultAddress &&loggedInUser.defaultAddress.location);
    // setTargetLocation({...loggedInUser.defaultAddress,...latLng});
    setSideBarOpen(true);
  };
  // Address Delete 
  const removeAddressCallFunc = (id: number) => {
    ApiRequest("DELETE", `address/${id}`, {
      isAuthenticated: false,
      token: loggedInUser.token,
    })
      .then((res) => {
        if (res.success) {
          setReload({ ...reload, address: !reload.address });
          setShowAlert1({ status: true, message: res.message });
          if (selectObject.address && selectObject.address.id === id) {
            setSelectObject({ ...selectObject, address: {} })
          }
        }
      })
      .catch((err) =>
        setShowAlert1({ status: true, message: err.message })
      );
  };

  const AddressRemoveBtnClick = (id: number) => {
    let answer = window.confirm("Are you Sure want to Delete?");
    if (answer) {
      removeAddressCallFunc(id);
      setSelectObject({ ...selectObject, address: {} });

    }
    else {
      setSelectObject({ ...selectObject, address: {} });
    }
  }
  console.log({ selectObject })

  return (
    <>
      <HeaderLocal
        setFilterData={setFilterData}
        show={{ ul: false, location: false, search: false, profile: false }}
      />
      <div className="local_schdule_div style-2">
        <IonToast
          isOpen={showAlert1.status}
          onDidDismiss={() => setShowAlert1({ status: false, message: "" })}
          message={showAlert1.message}
          duration={700}
        />
        <LocationSidebar
          getLocation={getLocation}
          position={position}
          showItem={[showItem, setShowItem]}
          locationRef={locationRef}
          sideBarOpen={[sideBarOpen, setSideBarOpen]}
        />

        <div className="container ">
          <div className="row">
            <div className="col-md-12 col-lg-7 schedule_pickup_div style-2">
              <Accordion>
                <div className="date_nd_time">
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <div className="schedule_date">
                        <p className="schedule_title">Enter Date</p>
                        <div className="calendar_nd_date">
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className=" w-100">
                              <div className="d-flexs align-items-center">
                                <IonIcon
                                  className={
                                    JSON.stringify(selectObject.date) === "{}"
                                      ? ""
                                      : "completed"
                                  }
                                  icon={calendar}
                                ></IonIcon>
                                {JSON.stringify(selectObject.date) !== "{}" ? (
                                  <span>
                                    {selectObject.date.date}/
                                    {selectObject.date.month}/
                                    {selectObject.date.year}
                                  </span>
                                ) : (
                                  <span>DD/MM/YY</span>
                                )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <DatePicker
                                className="schedule_calendar"
                                // selected={tomorrowDate}
                                onChange={onChange}
                                minDate={tomorrowDate}
                                startDate={tomorrowDate}
                                // endDate={endDate}
                                // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                                inline
                              />
                              {/* <Calendar
                                className="schedule_calendar"
                                defaultActiveStartDate={tomorrowDate}
                                minDate={tomorrowDate}
                                onChange={(e)=>{
                                  onChange(e)
                                  setTimeout(() => {
                                    selectDate()
                                  });
                                }}
                                value={value ? value : null}
                              /> */}
                              <Accordion.Header
                              onClick={selectDate} 
                              className="ok_btn date_ok_btn">
                                OK
                              </Accordion.Header>
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <div className="schedule_time">
                        <p className="schedule_title">Enter Time</p>
                        <div className="calendar_nd_date">
                          <Accordion.Item eventKey="2">
                            <Accordion.Header className=" w-100">
                              <div className="d-flexs align-items-center">
                                <IonIcon
                                  className={
                                    timeDate.time.title === "" ||
                                      selectObject.time.title === ""
                                      ? ""
                                      : "completed"
                                  }
                                  icon={time}
                                ></IonIcon>
                                <span>
                                  {selectObject.time.title === ""
                                    ? "00.00 - 00.00"
                                    : selectObject.time.title}
                                </span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="time_details">

                                {TIME_OPTIONS.map((t, i) => (
                                  <span
                                    className={
                                      timeDate.time.title === t.value ||
                                        selectObject.time.title === t.value
                                        ? "active_time"
                                        : ""
                                    }
                                    onClick={() => timeDetails(t.value)}
                                  >
                                    {t.label}
                                  </span>
                                ))}


                              </div>
                              <Accordion.Header onClick={selectTime} className="ok_btn">
                                OK
                              </Accordion.Header>
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="location_tab ">
                  <div className="">
                    <p className="schedule_title">Select an Address</p>
                    <Accordion.Item eventKey="3">
                      <div className="address_local calendar_nd_date">
                        <Accordion.Header className="w-100">
                          <div className="d-flexs align-items-center">
                            <IonIcon
                              style={
                                JSON.stringify(selectObject.address) === "{}"
                                  ? {}
                                  : { color: "#3AB34A" }
                              }
                              className="location_ion_icon"
                              icon={location}
                            ></IonIcon>
                            {JSON.stringify(selectObject.address) === "{}" ? (
                              <span>Choose Address</span>
                            ) : (
                              <span>{selectObject.address.title}</span>
                            )}
                            <IonIcon
                              className="arrow_down_icon"
                              icon={chevronDown}
                            ></IonIcon>
                          </div>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body>
                        <ul className="local_address_ul">
                          {JSON.stringify(address) === "[]" ? (
                            <p className="text-center my-2">
                              No Address Add Yet!
                            </p>
                          ) : (
                            address.map((item: any) => (
                              <li
                                key={item.id}
                                className={
                                  selectObject.address.id === item.id
                                    ? 'active local_address_li'
                                    : 'local_address_li'
                                }
                                onClick={() => selectAddress(item)}
                              >
                                <div>

                                  <p className="address_title">{item.title}</p>
                                  <p className="address_line">{item.line1}</p>
                                </div>
                                <div onClick={() => AddressRemoveBtnClick(item.id)}>
                                  <IonIcon
                                    className="close_icon"
                                    icon={trashOutline}
                                  />
                                </div>
                              </li>
                            ))
                          )}
                          <li onClick={addressClick} className="local_address_li">Add New Address</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </div>

                <div className="location_tab hidden">
                  <div className="">
                    <p className="schedule_title">
                      Repeat Pickup{" "}
                      <span className="local_optional_text">Optional</span>
                    </p>
                    <div className="repeat_local calendar_nd_date">
                      <div className="d-flexs align-items-center icon_nd_select">
                        <IonIcon
                          style={
                            selectObject.repeat.title === "None"
                              ? {}
                              : { color: "#3AB34A" }
                          }
                          className="location_ion_icon"
                          icon={repeat}
                        ></IonIcon>
                        <select
                          value={selectObject.repeat.title}
                          onChange={(e) => repeatChange(e)}
                          className="repeat_pickup"
                        >
                          <option value="None">None</option>
                          <option value="Every Day">Every Day</option>
                          <option value="Every Week">Every Week</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="location_tab">
                  <div className="">
                    <p className="schedule_title">
                      Estimated Weight{" "}
                    </p>
                    <div className="remarks_local calendar_nd_date">
                      <select
                        onChange={(e) => validaeWeight(e)}
                      >
                        <option value="-1">-- Select --</option>
                        {WEIGHT_RANGE.map((w, i) => (
                          <option value={w.value}>{w.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">

                  <div className="col-md-6 mt-3">
                    <div className="location_tab ">
                      <div className="">
                        <p className="schedule_title">
                          Remarks{" "}
                          <span className="local_optional_text">Optional</span>
                        </p>
                        <div className="remarks_local calendar_nd_date">
                          <input className="remarks_input" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="location_tab">
                      <div className="">
                        <p className="schedule_title">
                          Upload Photos{" "}
                          <span className="local_optional_text">Optional</span>
                        </p>
                        <div className="upload_local">
                          {/* <IonIcon className='location_ion_icon' icon={cloudUpload}></IonIcon> */}
                          <Accordion.Item eventKey="4">
                            <div
                              className="cloud_upload_icon icon_icon_name"
                              style={file.length ? { color: "green" } : {}}
                            >
                              <Accordion.Header className="uploadIcon_uploadFile_name w-100">
                                <IonIcon className="cloud_Ion_icon" icon={cloudUpload} />
                                {file.length ? (
                                  <p>
                                    {file[0].name}{" "}
                                    {file.length > 1
                                      ? `& ${file.length - 1}More`
                                      : null}
                                  </p>
                                ) : null}
                              </Accordion.Header>
                            </div>
                            <Accordion.Body>
                              <div className="photo_upload_div">
                                <MultipleImageUpload
                                  setFile={[file, setFile]}
                                  show={true}
                                />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div className="location_tab">
                  {JSON.stringify(selectObject.address) !== "{}" &&
                    selectObject.time.title !== "" &&
                    selectObject.date !== "" ? (
                    LoadingSpinner ? (
                      <button type="submit" className="schedule_pickup_button_local">
                        <IonSpinner name="lines" />
                      </button>
                    ) : (
                      <IonButton
                        onClick={(e) => scheduleClick(e)}
                        type="submit"
                        className="schedule_pickup_button_local"
                      >
                        Schdule A Pickup
                      </IonButton>
                    )
                  ) : (
                    <button
                      type="submit"
                      disabled
                      className="schedule_pickup_button_local"
                    >
                      Schdule A Pickup
                    </button>
                  )}

                  {JSON.stringify(selectObject.address) !== "{}" &&
                    selectObject.time.title !== "" &&
                    selectObject.date !== "" ? null : (
                    <>
                      <p className="help_text">
                        Facing Problems? Call us at
                        <a href="tel:+918595358613">+918595358613</a>
                      </p>
                      <p className="fill_schedule_text">
                        <IonIcon className="help_circle_icon" icon={helpCircle}></IonIcon>
                        Please fill all the required details to schedule pickup
                      </p>
                    </>

                  )}
                </div>

              </Accordion>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default LocalSchedule;
