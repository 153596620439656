import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonToast,
  IonSpinner,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import CloseIcon from "@material-ui/icons/Close";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import RoomIcon from "@material-ui/icons/Room";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import React, { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UseReload, UserLocation } from "../../App";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import MapWithAMarker from "../SearchAddress/MapWithAMarker";
import "./MapAddress.css";
import { userIsNow } from "../../Events";
type Inputs = {
  example: string;
  exampleRequired: string;
};
interface ContainerProps {
  Header: boolean;
}

const MapAddress: React.FC<ContainerProps> = ({ Header }) => {
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [moreDetails, setMoreDetails] = useState(false);
  const [heightFull, setHeightFull] = useState(false);
  const { register, handleSubmit } = useForm<Inputs>();
  const [selectTag, setSelectTag] = useState("");
  const [error, setError] = useState<any>({ status: false, message: "" });
  const [err, setErr] = useState(false);
  const [update, setUpdate] = useState(false);
  const history = useHistory();
  const { state } = useLocation<any>();
  const [otherFull, setOtherFull] = useState(false);
  const [reload, setReload] = useContext<any>(UseReload);
  const [loadingSpin, setLoadingSping] = useState(false);

  const addMoreDetails = () => {
    setMoreDetails(true);
    setHeightFull(true);
  };

  const nonFull = () => {
    setHeightFull(false);
    setMoreDetails(false);
  };

  const onSubmit = (info: any) => {
    if (selectTag !== "") {
      if (selectTag !== undefined) {
        const sentData = {
          ...targetLocation,
          address: info.address,
          line1: info.line1,
          selectLocation: info.name,
          lat: targetLocation.lat,
          lon: targetLocation.lng,
          landmark: info.landmark,
          houseflatno: info.houseflatno,
          pincode: info.pincode,
          label: selectTag,
        };
        console.log({ sentData });
        if (state.action === "PUT") {
          setLoadingSping(true);

          ApiRequest("PUT", `address/${targetLocation && targetLocation.id}`, {
            body: sentData,
            isAuthenticated: false,
            token: loggedInUser.token,
          })
            .then((res: any) => {
              console.log(res, "PUT");
              setLoadingSping(false);
              if (res.success) {
                setTargetLocation(sentData);
                setHeightFull(false);
                setMoreDetails(false);
                setUpdate(true);
                setReload({ ...reload, address: !reload.address });
                setTimeout(() => {
                  if (state.pathname === undefined) {
                    history.push("/home");
                  } else {
                    history.push(state.pathname);
                  }
                }, 2000);
              }
            })
            .catch((err: any) => setErr(err));
        } else {
          setLoadingSping(true);
          ApiRequest("POST", "address", {
            body: sentData,
            isAuthenticated: false,
            token: loggedInUser.token,
          })
            .then((res: any) => {
              console.log(res, "POST");

              setLoadingSping(false);
              if (res.success) {
                setTargetLocation(sentData);
                setHeightFull(false);
                setMoreDetails(false);
                setUpdate(true);
                setReload({ ...reload, address: !reload.address });
                setTimeout(() => {
                  if (state.pathname === undefined) {
                    history.push("/home");
                  } else {
                    history.push(state.pathname);
                  }
                }, 2000);
              } else {
                setError({
                  message: res.message || "Error Adding Address, Please Contact Support",
                  status: true,
                });
              }
            })
            .catch((err: any) => setErr(err));
        }
      } else {
        setError({ message: "Tag Must Be Selected", status: true });
      }
    } else {
      setError({ message: "Tag Must Be Selected", status: true });
    }
  };

  const tagAs = (info: string, e: any) => {
    if (info === "other") {
      setOtherFull(true);
      setSelectTag(e.target.value);
    } else {
      setSelectTag(info);
    }
  };

  useEffect(() => {
    userIsNow(window.location.pathname);
    if (targetLocation && targetLocation.title) {
      if (targetLocation && targetLocation.title === "home") {
        setSelectTag("home");
      } else if (targetLocation && targetLocation.title === "work") {
        setSelectTag("work");
      } else {
        setSelectTag("other");
        setOtherFull(true);
      }
    }
  }, []);

  console.log({ state });

  return (
    <>
      <IonToast
        isOpen={error.status}
        onDidDismiss={() => setError({ status: false, message: "" })}
        message={error.message}
        duration={1000}
      />
      <IonToast
        isOpen={err || update}
        onDidDismiss={() => {
          setErr(false);
          setUpdate(false);
        }}
        message={`${err === true ? "Address Not Updated" : "Address Updated"}`}
        duration={700}
      />

      <IonHeader id="ion_header" className="d-flex-pickupdetails">
        <div onClick={() => history.goBack()} className="d-flex-pickupdetails">
          <img
            src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
            alt="back_array"
          />
          <p> Back</p>
        </div>

        {heightFull && <CloseIcon onClick={nonFull} />}
      </IonHeader>

      <IonContent>
        <div className="mobile_view_map">
          <MapWithAMarker />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid
            className={heightFull ? "address_grid full" : "address_grid"}
          >
          
            {moreDetails ? (
              <>
               <IonRow>
                  <IonCol className="setLocation">
                <h3>{state.action === "PUT" ? "Edit" : "Set"} Location</h3>
                  </IonCol>
                </IonRow>
                <IonRow className="add_more_detail_page ">
                  <IonCol className="setLocation heading">
                    <p className="margin_bottom_0">Address</p>
                    <div className="input_div">
                      <input
                        ref={register({ required: true })}
                        name="address"
                        className="detail_input margin_bottom_0"
                        placeholder="AREA NAME"
                        defaultValue={targetLocation && targetLocation.line1}
                        required
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="input_div">
                      <input
                        ref={register({ required: true })}
                        name="houseflatno"
                        defaultValue={
                          targetLocation && targetLocation.houseflatno
                        }
                        className="detail_input"
                        placeholder="HOUSE/ FLAT NO"
                        type="text"
                        required
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="input_div">
                      <label className='border_css'></label>
                      <input
                        ref={register({ required: true })}
                        name="landmark"
                        defaultValue={targetLocation && targetLocation.landmark}
                        className="detail_input"
                        placeholder="LANDMARK"
                        type="text"
                        required
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="input_div">
                      <label className='border_css'></label>
                      <input
                        ref={register({ required: true })}
                        name="pincode"
                        defaultValue={targetLocation && targetLocation.pincode}
                        className="detail_input"
                        placeholder="PINCODE/ZIP-CODE"
                        type="number"
                        required
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <p className="font_color">TAG AS</p>
                    <div className="tag_item">
                      <div
                        onClick={(e) => tagAs("home", e)}
                        className={`tag_item_self cursor-pointer ${
                          selectTag === "home" ? "selectTag" : ""
                        }`}
                      >
                        <HomeOutlinedIcon />
                        <p className="margin-5 ">HOME</p>
                      </div>
                      <div
                        onClick={(e) => tagAs("work", e)}
                        className={`tag_item_self cursor-pointer ${
                          selectTag === "work" ? "selectTag" : ""
                        }`}
                      >
                        <WorkOutlineIcon />
                        <p className="margin-5 ">WORK</p>
                      </div>
                      <div
                        id={otherFull ? "fullOther" : ""}
                        className={`tag_item_self cursor-pointer ${
                          otherFull === true ? "selectTag" : ""
                        }`}
                      >
                        <RoomIcon onClick={(e) => tagAs("other", e)} />

                        <p
                          onClick={(e) => tagAs("other", e)}
                          className="margin-5 "
                        >
                          OTHER
                        </p>
                        {otherFull && (
                          <input
                            placeholder="e.g: Dad's place, Jim's Home"
                            onChange={(e) => tagAs("other", e)}
                            defaultValue={
                              targetLocation && targetLocation.title
                            }
                            autoFocus={true}
                            className={
                              otherFull ? "other_input full" : "other_input"
                            }
                          ></input>
                        )}

                        {otherFull && (
                          <IonIcon
                            onClick={() => setOtherFull(false)}
                            className="other_icon "
                            icon={closeOutline}
                          ></IonIcon>
                        )}
                      </div>
                    </div>

                    <IonButton
                      mode="ios"
                      color="success"
                      type="submit"
                      className="saveBtn"
                      expand="block"
                    >
                      {loadingSpin ? (
                        <IonSpinner name="lines" />
                      ) : (
                        "  SAVE AND PROCEED "
                      )}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              <>
                <IonRow>
                  <IonCol className="setLocation">
                 
                  <h3>{state.action === "PUT" ? "Edit" : "Set"} Location</h3>
            
                    <p className="margin_bottom_0">Address</p>
                    <p className="margin_bottom_0 font_weight_bold">
                      {(targetLocation && targetLocation.address) ||
                        (targetLocation && targetLocation.line1)}
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonButton
                      mode="ios"
                      color="success"
                      onClick={addMoreDetails}
                      expand="block"
                      fill="outline"
                    >
                      ADD MORE DETAILS
                    </IonButton>
                    {/* <Link to={state}>
                      <IonButton
                        mode="ios"
                        color="success"
                        className="confirm_btn"
                        expand="block"
                      >
                        SKIP & ADD LATER
                      </IonButton>
                    </Link> */}
                  </IonCol>
                </IonRow>
              </>
            )}
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

export default MapAddress;
