import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
} from "@ionic/react";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserLocation } from "../../App";
import Title from "../Title";
import "./EditCard.css";

interface ContainerProps {
  Header: boolean;
}
const EditCard: React.FC<ContainerProps> = (props) => {
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { title, line1 } = location.state;

  const addressSet = () => {
    setTargetLocation({ ...targetLocation });
    history.push("/mapaddress");
  };

  return (
    <>
      {props.Header && (
        <IonHeader className="d-flex-pickupdetails" id="edit_card_header">
          <img
            onClick={() => history.goBack()}
            src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
            alt=""
          />
          <p onClick={() => history.goBack()}>Back</p>
        </IonHeader>
      )}
      <IonContent>
        <Title title="Editing Home" />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="input_form">
                <p className="input_title">Name</p>
                <input
                  className="edit_address_input"
                  placeholder="Enter your Name"
                  name="name"
                  defaultValue={title}
                  type="text"
                />
              </div>
              <div className="input_form">
                <p className="input_title">Address</p>
                <input
                  onClick={addressSet}
                  className="edit_address_input"
                  placeholder="Enter your Address"
                  name="address"
                  value={line1}
                  type="text"
                  readOnly
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButton
          className="bottom_Btn Edit-card-button"
          type="submit"
          color="success"
          expand="full"
        >
          SAVE
        </IonButton>
      </IonContent>
    </>
  );
};

export default EditCard;
