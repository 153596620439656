import { IonIcon } from "@ionic/react";
import { bagOutline, homeOutline, locationOutline } from "ionicons/icons";
import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseReload, UserLocation } from "../../../App";
import ApiRequest from "../../../Services/Api.Service";
import useLocalStorage from "../../../Services/useLocalStorage";
import "./WebHeader.css";
import { IonToast } from "@ionic/react";

interface sideBar {
  setSideBarOpen: any;
  setShowItem: any;
  setValue:any
}

const AddressSet: React.FC<sideBar> = (props) => {
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [addMore, setAddMore] = useState(true);
  const { register, handleSubmit, setValue } = useForm();
  const [category, setCategory] = useState("");
  const [otherClick, setOtherClick] = useState(false);
  const [showToast1, setShowToast1] = useState({ status: false, message: "" });
  const [reload, setReload] = useContext<any>(UseReload);

  const addMoreClick = () => {
    setAddMore(true);
  };
  const categoryClick = (itemName: any) => {
    if (itemName === "other") {
      setOtherClick(true);
    } else {
      setCategory(itemName);
    }
  };

  const onSubmit = (info: any) => {
    if (category !== "") {
      if (category !== undefined) {
        const totalData = {
          ...targetLocation,
          address: info.address,
          landmark: info.landmark,
          line1: info.houseflatNO,
          lon: targetLocation.lng,
          houseflatno: info.houseflatno,
          label: category,
          pincode: info.pincode,
          city:'Unknown',
          state:'Unknown'
        };

        ApiRequest("POST", "address", {
          body: totalData,
          isAuthenticated: false,
          token: loggedInUser.token,
        })
          .then((res: any) => {
            if (res.success) {
              setTargetLocation(totalData);
              setReload({ ...reload, address: !reload.address });
              setShowToast1({ status: true, message: res.message });
              const address = res.data?.id
              props.setShowItem("search");
              props.setValue('')
              setTimeout(() => {
                props.setSideBarOpen(false);
              }, 1500);
            } else {
              setShowToast1({ status: true, message: 'Area not serviceable' });
            }
          })
          .catch((err: any) =>
            setShowToast1({ status: true, message: err.message })
          );
      }
    }
  };

  const skipAddLater = () => {
    props.setSideBarOpen(false);
    props.setShowItem("search");
  };
  useEffect(() => {
    if (targetLocation) {
      console.log("Location:", targetLocation)
      setCategory(targetLocation.title);
      setValue('address', targetLocation.line1);
      if (targetLocation && targetLocation.title) {
        if (
          targetLocation.title !== "home" &&
          targetLocation.title !== "work"
        ) {
          setCategory("other");
          setOtherClick(true);
        }
      }
    }
  }, []);

  return (
    <div>
      <IonToast
        isOpen={showToast1.status}
        onDidDismiss={() => setShowToast1({ status: false, message: "" })}
        message={showToast1.message}
        duration={1000}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="current_location search">
          <p className="location_text text-truncate">
            {" "}
            <span> Address</span>
            <input
              ref={register}
              type="text"
              // value={targetLocation && targetLocation.line1}
              name="address"
              onChange={() => ""}
              required
            />
          </p>
        </div>
        {addMore ? (
          <>
            <div className="address_search_box house_box">
              <input
                onChange={() => ""}
                ref={register}
                name="houseflatNO"
                placeholder="House/ Flat No"
                type="text"
                className="searchBox_input"
                id=""
                defaultValue={targetLocation && targetLocation.houseflatNO}
                required
              />
            </div>
            <div className="address_search_box landmark_box">
              <input
                onChange={() => ""}
                ref={register}
                name="landmark"
                placeholder="Landmark"
                type="text"
                className="searchBox_input"
                id=""
                defaultValue={targetLocation && targetLocation.landmark}
                required
              />
            </div>
            <div className="address_search_box pincode_box">
              <input
                onChange={() => ""}
                ref={register}
                name="pincode"
                placeholder="Pincode"
                type="number"
                className="searchBox_input"
                id=""
                defaultValue={targetLocation && targetLocation.pincode}
                required
              />
            </div>

            {otherClick ? (
              <div className="Other_click">
                <div
                  onClick={() => categoryClick("first_other")}
                  className="first_other "
                >
                  <IonIcon icon={locationOutline} />
                  <span>Other</span>
                </div>
                <div className="second_other">
                  <input
                    defaultValue={targetLocation && targetLocation.title}
                    onChange={(e: any) => setCategory(e.target.value)}
                    autoFocus={true}
                    placeholder="Dad's home, my man cave"
                    type="text"
                    name="other"
                    ref={register}
                    id=""
                  />
                </div>
                <div className="third_other">
                  <p
                    onClick={() => setOtherClick(false)}
                    className="cancel_color"
                  >
                    Cancel
                  </p>
                </div>
              </div>
            ) : (
              <div className="title_set">
                <div
                  onClick={() => categoryClick("home")}
                  className={
                    category === "home"
                      ? "first_title active_category"
                      : "first_title "
                  }
                >
                  <IonIcon icon={homeOutline} />
                  <span> Home</span>
                </div>
                <div
                  onClick={() => categoryClick("work")}
                  className={
                    category === "work"
                      ? "second_title active_category"
                      : "second_title "
                  }
                >
                  <IonIcon icon={bagOutline} />
                  <span> Work</span>
                </div>
                <div
                  onClick={() => categoryClick("other")}
                  className={
                    category === "other"
                      ? "third_title active_category"
                      : "third_title "
                  }
                >
                  <IonIcon icon={locationOutline} />
                  <span>Other</span>
                </div>
              </div>
            )}

            <button 
            type="submit" 
            className="current_location skip_btn">
              <p>SAVE ADDRESS & PROCEED</p>
            </button>
          </>
        ) : (
          <>
            <div
              onClick={addMoreClick}
              className="current_location add_more_details"
            >
              <p>ADD MORE DETAILS</p>
              <small>for faster checkout</small>
            </div>
            {/* <div onClick={skipAddLater} className="current_location skip_btn">
              <p>SKIP & ADD LATER</p>
              <small>to quickly see retaurants</small>
            </div> */}
          </>
        )}
      </form>
    </div>
  );
};

export default AddressSet;
