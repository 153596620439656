import React, { useEffect, useContext } from "react";
import "./Header.css";
import { IonGrid, IonRow, IonCol, IonBadge } from "@ionic/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  DefaultAddress,
  UserContext,
  UseReload,
  UserLocation,
} from "../../App";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";

const Header: React.FC = (props) => {
  const [cartItems] = useContext<any>(UserContext);
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const history = useHistory();
  const {pathname} = useLocation();
  const [reload, setReload] = useContext<any>(UseReload);
  const [defaultAddres, setDefaultAddres] = useContext<any>(DefaultAddress);

  useEffect(() => {
    ApiRequest("GET", "address/", {
      isAuthenticated: false,
      token: loggedInUser.token,
    })
      .then((data) => {
        setDefaultAddres({ ...data.data[0] });
      })
      .catch((err) => console.log(err));
  }, [reload.address]);

  const addressClick = () => {
    const latLng = JSON.parse(defaultAddres.location);
    setTargetLocation({ ...defaultAddres, ...latLng });
    history.push({pathname:"/mapaddress", state:{pathname:pathname, action:'PUT'}});
  };

  return (
    <div className="container">
      <IonGrid>
        <IonRow className="header_row">
          <IonCol className="header_left d-flex-header">
            {JSON.stringify(defaultAddres) !== "{}" ? (
              <div className="two_section">
                <div className="header-first">
                  <p className="margin_bottom_0" style={{textTransform:'uppercase', fontWeight:'bold'}}>
                    <img src="https://ik.imagekit.io/domestica/swapeco/location-1_TL_yi5Ia1.svg" style={{marginRight:"7px"}} alt='location'/>
                    {defaultAddres && defaultAddres.title}
                  </p>
                  <Link to={{pathname:"/searchaddress", state:{pathname:pathname, action:'POST'}}}>
                    <img
                      className="arrow_iconss"
                      src="https://ik.imagekit.io/y8s2vhk66ef/Vector705_Stroke__GnGbJT83e.png"
                      alt=""
                    />
                  </Link>
                </div>
                <p onClick={addressClick} className="header-full-address">
                  {/* <span className="text-uppercase font-weight-bold">
                    {defaultAddres && defaultAddres.title}
                  </span> */}
                  {defaultAddres && defaultAddres.line1}
                </p>
              </div>
            ) : (
              <Link to={{pathname:"/searchaddress", state:{pathname:pathname, action:'POST'}}}>
                <div className="header-first">
                  <img
                    className="location_icon"
                    src="https://ik.imagekit.io/y8s2vhk66ef/Subtract_UmDJYyQo2.png"
                    alt=""
                  />

                  <p>Select a Location</p>
                  <img
                    className="arrow_icon"
                    src="https://ik.imagekit.io/y8s2vhk66ef/Vector705_Stroke__GnGbJT83e.png"
                    alt=""
                  />
                </div>
              </Link>
            )}

            <div className="header_cart_icon">
              {cartItems.length ? (
                <div className="cartIcon">
                  <Link to="/cartitems">
                    {" "}
                    <img
                      className="cart_icons header_icon_image"
                      src="https://ik.imagekit.io/y8s2vhk66ef/Vector_3__DFPL3VQF8.png"
                      alt="cartimage"
                    />
                    <IonBadge
                      className="badgeStyle header_icon_badge"
                      color="danger"
                    >
                      {cartItems.length}
                    </IonBadge>
                  </Link>
                </div>
              ) : (
                <div className="cartIcon">
                  <Link to="/cartitems">
                    {" "}
                    <img
                      className="cart_icons header_icon_image"
                      src="https://ik.imagekit.io/y8s2vhk66ef/Vector_3__DFPL3VQF8.png"
                      alt=""
                    />
                  </Link>
                </div>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Header;
