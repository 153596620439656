import { IonCol, IonAlert, IonGrid,  IonRow, IonImg } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserProfile } from "../../../App";
import ApiRequest from "../../../Services/Api.Service";
import "./PageTwoContent.css";
import ShowModal from "./ShowModal";

interface ContainerProps {
  filterData: any;
  setAddItems: any;
  popUp: boolean;
}

const PageTwoContent: React.FC<ContainerProps> = (props) => {
  const [profile, setProfile] = useContext<any>(UserProfile);
  const setAddItems = props.setAddItems;
  const [filterDate, setFilterData] = props.filterData;
  const [open, setOpen] = useState<any>(false);
  const [items, setItems] = useState({});
  const [totalData, setTotalData] = useState<any>([]);
  const [itemAdded, setItemAdded] = useState({ status: false, message: "" });
  const fetchData = () => {
    return ApiRequest(
      "GET",
      "/app/rate-list",
      profile && { token: profile.token }
    );
  };
  const { data, status } = useQuery("rents", fetchData);

  const plusClick = (item: any) => {
    return false;
    if (props.popUp) {
      setOpen(!open);
      setItems(item);
    }
    setAddItems(item);
  };

  const searchData = totalData.filter((val: any) => {
    if (val == "") {
      return val;
    } else if (val.title.toLowerCase().includes(filterDate.toLowerCase())) {
      return val;
    }
  });

  useEffect(() => {
    let child: any = [];

    if (status === "success") {
      data.data.map((item: any) => (child = [...child, ...item.children]));
    }

    setTotalData(child);
  }, [filterDate]);

  // useEffect(()=>{
  //   let child:any = [];
  //   allRent.map((item:any) =>  child.replace(...item.children))
  //   setTotalData(child)
  // },[allRent])
  useEffect(() => {
    setTimeout(() => {
      setItemAdded({ status: false, message: "" });
    }, 1500);
  }, [itemAdded.status]);

  return (
    <div className="container page_two_content">
      {searchData.length === totalData.length ? (
        <IonGrid>
          {open === true && (
            <ShowModal
              setItemAdded={setItemAdded}
              edit={false}
              open={[open, setOpen]}
              item={items}
            />
          )}

          <IonRow>
            <IonCol>
              <h4 className="recyclabels_title">All Recyclables</h4>
            </IonCol>
          </IonRow>

          {status === "success" &&
            data.data.map((block: any) => (
              <IonRow key={block.id}>
                <IonCol>
                  {JSON.stringify(block.children) !== "[]" && (
                    <h4 className="recyclables">{block.title}</h4>
                  )}

                  <div className="blockTwoPage style-2">
                    {block.children.map((item: any) => (
                      <div
                        onClick={() => plusClick(item)}
                        key={item.id}
                        className="block_box"
                      >
                        <div className="item_icon">
                          <img
                            src={`https://swapeco.com/${item.img}`}
                            alt={"icon"}
                            ref={img => block.img = img}
                            onError={()=>{
                              block.img.src=''
                            }}
                          />
                        </div>
                        <div className="title_rate">
                          <p>{item.title}</p>
                          <p>
                            RS {item.rate}/
                            {item.m_unit === "null" ? "KG" : item.m_unit}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </IonCol>
              </IonRow>
            ))}
        </IonGrid>
      ) : (
        <IonCol>
          {open === true && (
            <ShowModal
              setItemAdded={setItemAdded}
              edit={false}
              open={[open, setOpen]}
              item={items}
            />
          )}
          {searchData &&
            searchData.map((item: any) => (
              <div key={item.id} className="block_box">
                  <div className="item_icon">
                          <img
                            src={`https://swapeco.com/${item.img}`}
                            alt={"icon"}
                          />
                        </div>
                <div className="title_rate">
                  <p>{item.title}</p>
                  <p>
                    RS {item.rate}/{item.m_unit === "null" ? "KG" : item.m_unit}
                  </p>
                </div>
              </div>
            ))}
          {searchData && searchData.length === 0 && (
            <p className="text-center">No Search Found!</p>
          )}
        </IonCol>
      )}
      <IonAlert
        isOpen={itemAdded.status}
        onDidDismiss={() => setItemAdded({ status: false, message: "" })}
        cssClass="item_added_check"
        header={itemAdded.message}
        mode="ios"
        animated={true}
        message={`<img src="https://ik.imagekit.io/y8s2vhk66ef/Icon_check_outline_JeVSTUtt3.png" alt="Check Icon" style="border-radius: 2px">`}
      />
    </div>
  );
};

export default PageTwoContent;
