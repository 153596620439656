import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { userTabClick } from "../../Events";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import Title from "../Title";
import SideBar from "../WebView/SideBar/SideBar";
import "./ReferFriend.css";

interface Container {
  Header: boolean;
}

const AppSettings: React.FC<Container> = () => {
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [reffarelCode, setReffarelCode] = useState<any>('');
  const [showToast1, setShowToast1] = useState(false);
  const history = useHistory();
  useEffect(() => {
    userTabClick(window.location.pathname);

//Get Reffer Code for post
    ApiRequest("POST", "auth/ref", {
      isAuthenticated: false,
      token: loggedInUser.token,
      body: { phone: loggedInUser.phone },
    }).then((res) => {
      const referCode  = res.data[0];
      setReffarelCode(referCode.referral_code);
    });
  }, []);

  const shareRefferalBtn = async () => {

    let newVariable: any = window.navigator;

    if (newVariable && newVariable.share) {
      newVariable
        .share({
          title: "Swapeco Share Code ",
          text: `Sell your scrap Online, Schedule a scrap pickup in minutes and get paid for your scrap. Download Now.`,
          url: `https://swapeco.com/?utm_source=app&ref=${reffarelCode}`,
        })
        .then((res:any) =>{
          setShowToast1(true)
        })
        .catch((error: any) => console.log("Error sharing", error));
    } else {
      alert("share not supported");
    }
  };

  return (
    <>
      <IonHeader id="ion_header" className="d-flex-pickupdetails">
        <img
          onClick={() => history.goBack()}
          src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
          alt=""
        />
        <p onClick={() => history.goBack()}>Back</p>
      </IonHeader>

      {
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Successfully Share !"
          duration={1000}
        />
      }
      <IonContent>
        <Title title="Refer A Friend" />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="settingImg">
                <img
                  src="https://ik.imagekit.io/y8s2vhk66ef/Humaaans_Plants_3SLyCVGEDG.png"
                  alt="Human Plant "
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p className="description-setting">
                Refer and earn Rs X with every new user that signs up with your
                code. As soon as they schedule their first pickup, we’ll give
                you a Rs X more on your next pickup.
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className="code_key">
                <p className="code">referral_code</p>
                <h3 className="upcoming_pickup_detail_title">
                  {reffarelCode}
                </h3>
                <IonButton
                  className="share_btn"
                  onClick={() => shareRefferalBtn()}
                  color="success"
                  mode='md'
                >
                  SHARE CODE
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <SideBar />
    </>
  );
};

export default AppSettings;
