import { IonContent, IonPage, IonToolbar } from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { UseActiveTabShow, UserProfile } from "App";
import Header from "Components/Header/Header";
import MainContent from "Components/MainContent/MainContent";
import HomeContent from "Components/WebView/Home/HomeContent";
import "Components/WebView/Home/MiddleContent.css";
import "./Tab1.css";
import ApiRequest from "Services/Api.Service";
import useLocalStorage from "Services/useLocalStorage";

const Tab1: React.FC<any> = () => {
  const location = useLocation();
  const [profile, setProfile] = useContext<any>(UserProfile);

  const [homeContentInfo, setHomeContentInfo] = useState<any>({});
  const [loggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const  setActiveTabShow = useContext<any>(UseActiveTabShow);
  useEffect(() => {
    setProfile({ ...profile, location: location.pathname });
    ApiRequest("GET", "app/home", {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then((data) => {
      console.log("homeContentInfo", data)
      setHomeContentInfo(data.data);
    });
  }, []);

  useEffect(() => {
    setActiveTabShow[1](window.location.pathname);
  }, [window.location.pathname]);

  return (
    <IonPage>
      <div className="mobile-view">
        <IonToolbar>
          <Header />
        </IonToolbar>

        <IonContent fullscreen>
          <MainContent homeContentInfo={homeContentInfo} name="this is" />
        </IonContent>
      </div>
      <div className="web-view style-2">
        <HomeContent homeContentInfo={homeContentInfo} />
      </div>
    </IonPage>
  );
};

export default Tab1;
