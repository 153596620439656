import React, { useState, useEffect, useContext } from "react";
import { IonChip, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import HeaderLocal from "Components/Header/HeaderLocal";
import { chevronDownOutline } from "ionicons/icons";
import { Accordion, Button } from "react-bootstrap";
import "./LocalPickupView.css";
import { UseReload } from "App";
import ApiRequest from "Services/Api.Service";
import useLocalStorage from "../../../Services/useLocalStorage";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import Skeleton from "Components/Skeleton";

const LocalPickupView: React.FC = () => {
  const [viewNow, setViewNow] = useState("upcoming");
  const [reload] = useContext<any>(UseReload);
  const history = useHistory();
  const [filterData, setFilterData] = useState("");
  const [status, setStatus] = useState<any>("pending");
  const [upcomingData, setUpcomingData] = useState<any>({
    status: false,
    fullData: [],
  });
  const [completeData, setCompleteData] = useState<any>({
    status: false,
    fullData: [],
  });
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const schduleClick = () => {
    history.push("/local-schedule-pickup");
  };

  useEffect(() => {
    setUpcomingData({ fullData: [], status: false });
    
    let params = new URLSearchParams({
      filter:`${encodeURIComponent('status=' + status)}`
    }).toString()

    const fetchUrl = `pickup/all?` + params
    
    console.log({fetchUrl})
    ApiRequest("GET", fetchUrl, {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then(
      (data) =>
        data.data && setUpcomingData({ fullData: [...data.data], status: true })
    );
  }, [status, reload.schedule, loggedInUser.token]);

  console.log({ upcomingData });
  return (
    <div className="local_pickup_div">
      <HeaderLocal
        setFilterData={setFilterData}
        show={{ ul: false, location: false, search: false, profile: false }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12 list_pickup_view">
            <div className="pickUp_view_local">
              <p className="pickups_title hidden" style={{display:'none'}}>
                <span
                  onClick={() => setViewNow("upcoming")}
                  className={viewNow === "upcoming" ? "active" : ""}
                >
                  Upcoming Pickups
                </span>
                <span
                  onClick={() => setViewNow("complete")}
                  className={viewNow === "complete" ? "active" : ""}
                >
                  Complete Pickups
                </span>
              </p>

              <Accordion className="text-center">
                {viewNow === "upcoming" ? (
                  <>
                    <IonGrid>
                      <IonRow>
                        <IonCol className="pending_status local_pending_status">
                          <IonChip
                            color="primary"
                            className={
                              status === "pending" ? "activePending" : "pending"
                            }
                            onClick={(i) => setStatus("pending")}
                          >
                            <span>Pending</span>
                          </IonChip>

                          <IonChip
                            color="success"
                            className={
                              status === "schedule"
                                ? "activeSchedule middle"
                                : "schedule middle"
                            }
                            onClick={(i) => setStatus("schedule")}
                          >
                            <span> Scheduled</span>
                          </IonChip>

                          <IonChip
                            color="danger"
                            className={
                              status === "cancelled"
                                ? "activeCanceled"
                                : "cancel"
                            }
                            onClick={(i) => setStatus("cancelled")}
                          >
                            <span>Cancelled</span>
                          </IonChip>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <ul className="pickups_list_ul style-2">
                      {status === "pending" ? (
                        upcomingData.status === false ? (
                          <Skeleton />
                        ) : upcomingData.status === true &&
                            upcomingData.fullData.length > 0 ? 
                              upcomingData.fullData
                                .map((item: any, i: number) => (
                                  <Schedule key={i} item={item} i={i} />
                                ))
                             : (
                              <p className="text-center mt-2">
                                Nothing Pending!
                              </p>
                            )
                      ) : null}
                      {status === "cancelled" ? (
                        upcomingData.status === false ? (
                          <Skeleton />
                        ) : upcomingData.status === true &&
                          upcomingData.fullData.length > 0 ? 
                          upcomingData.fullData
                            .map((item: any, i: number) => (
                              <Schedule key={i} item={item} i={i} />
                            ))
                         : (
                          <p className="text-center mt-2">
                            Nothing Cancelled
                          </p>
                        )
                      ) : null}
                      {status === "schedule" ? (
                        upcomingData.status === false ? (
                          <Skeleton />
                        ) : upcomingData.status === true &&
                          upcomingData.fullData.length > 0 ? 
                          upcomingData.fullData
                            .map((item: any, i: number) => (
                              <Schedule key={i} item={item} i={i} />
                            ))
                         : (
                          <p className="text-center mt-2">
                            Nothing Schedule!
                          </p>
                        )
                      ) : null}
                    </ul>
                  </>
                ) : (
                  <ul className="pickups_list_ul style-2">
                    {completeData.status === false ? (
                      <Skeleton />
                    ) : JSON.stringify(
                        completeData.fullData.filter(
                          (item: any) => item.status === "COMPLETED"
                        )
                      ) !== "[]" ? (
                      completeData.fullData
                        .filter((item: any) => item.status === "COMPLETED")
                        .map((item: any, i: number) => (
                          <Schedule key={i} item={item} i={i + 2000} />
                        ))
                    ) : (
                      <p className="text-center mt-2">
                        'Ops! There is no Completed !'
                      </p>
                    )}
                  </ul>
                )}
                <button
                  onClick={schduleClick}
                  className="local_next_button schedule_pickup_button"
                >
                  Schedule Another Pickup
                </button>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalPickupView;

const Schedule = ({ item, i }: any) => {
  return (
    <li>
      <Accordion.Item eventKey={i + 1}>
        <Accordion.Header className="accordion_btn">
          <div className="main_view">
            <span style={{minWidth:"54px"}}>
              <h5 className="pickup_details_day">
                {dayjs(item.pickup_date).toDate().toString().slice(0, 3)}
              </h5>
              <h6 className="pickup_details_data">
                {dayjs(item.pickup_date).date() < 10 && "0"}
                {dayjs(item.pickup_date).date()}
              </h6>
            </span>
            <span>
              <p className="pickup_details_name">
                {item.pickup_agent.status === "ASSIGNED"
                  ? "Pickup for " + item.pickup_agent.details.name
                  : item.pickup_agent.status}
              </p>
              <p className="pickup_details_weight">
                Total weight:{" "}
                { item.weight }
                {/* {item.items &&
                  item.items.reduce(function (prev: any, item: any) {
                    return prev + Number(item.quantity);
                  }, 0)}{" "} */}
                {/* KG */}
              </p>
              {/* <p className="pickup_details_price">
                Est. price:{" "}
                {item.items &&
                  item.items.reduce(function (prev: any, item: any) {
                    return (
                      prev + Number(item.quantity) * Number(item.rate.amount)
                    );
                  }, 0)}{" "}
                Rs
              </p> */}
            </span>
            <IonIcon className="down_arrow" icon={chevronDownOutline}></IonIcon>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="details_view view">
            <div className="details_d_flex ">
              <p className="details_view_ques">Location: </p>
              <p className="details_view_ans">{item.address.line1}</p>
            </div>
            <p style={{fontSize: "13px",fontWeight: 400, fontStyle: "italic",marginTop: "9px"}}>
              For rescheduling or cancellation please call us at <a href="tel:+918595358613">+918595358613</a>
            </p>
            {/* <div className="details_d_flex ">
              <p className="details_view_ques">Vendor: </p>
              <p className="details_view_ans">{item.address.title}</p>
            </div>

            <div className="details_d_flex ">
              <p className="details_view_ques">Payment Mode: </p>
              <p className="details_view_ans">Cash</p>
            </div> */}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </li>
  );
};
