import React from "react";
import "./AppSettings.css";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
} from "@ionic/react";
import Title from "../Title";
import { Link } from "react-router-dom";
import SideBar from "../WebView/SideBar/SideBar";

interface ContainerProps {
  Header: boolean;
}
const AppSettings: React.FC<ContainerProps> = (props) => {
  return (
    <>
      {props.Header && (
        <IonHeader id="ion_header" className="d-flex-pickupdetails">
          <Link to="/profile">
            {" "}
            <img
              src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
              alt=""
            />
          </Link>
          <Link to="/profile">
            {" "}
            <p>Back</p>
          </Link>
        </IonHeader>
      )}
      <IonContent>
        <Title title="App Settings" />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="settingImg">
                <img
                  src="https://ik.imagekit.io/y8s2vhk66ef/Humaaans_Plants_3SLyCVGEDG.png"
                  alt=""
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p className="description-setting">
                Refer and earn Rs X with every new user that signs up with your
                code. As soon as they schedule their first pickup, we’ll give
                you a Rs X more on your next pickup.
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className="code_key">
                <p className="code">Your Code</p>
                <h3 className="upcoming_pickup_detail_title">XV53U8</h3>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <SideBar />
    </>
  );
};

export default AppSettings;
