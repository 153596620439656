import {
  IonApp,
} from "@ionic/react";
/* Core CSS required for Ionic Components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useState } from "react";
import "./App.css";
import "./Theme/variables.css";
import AppMode from "Mode/AppMode";
import WebMode from "Mode/WebMode";

export const UserContext = React.createContext({});
export const SchedulePickUp = React.createContext<any>({});
export const UserProfile = React.createContext({});
export const UserLocation = React.createContext({});
export const UserNotification = React.createContext({});
export const UseReload = React.createContext({});
export const DefaultAddress = React.createContext({});
export const UseActiveTabShow = React.createContext({});
export const IsAppMode = process.env.REACT_APP_MODE ? true:false;

const App: React.FC = () => {
  const [reload, setReload] = useState({ address: false, schedule: false });
  const [profile, setProfile] = useState({ location: "" });
  const [cartItems, setCartItems] = useState([]);
  const [notification, setNotification] = useState([]);
  const [schedule, setSchedule] = useState({
    address: {},
    items: [],
    date: {},
    repeat: { title: "week", number: 2 },
    time: {},
  });
  const [activeTabShow, setActiveTabShow] = useState<any>("");
  const [targetLocation, setTargetLocation] = useState<any>({});
  const [defaultAddres, setDefaultAddres] = useState<any>({});

  const isMobile = 
    (window.location?.search?.indexOf("app=1") !== -1) || 
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );


  return (
    <IonApp>
      <UseActiveTabShow.Provider value={[activeTabShow, setActiveTabShow]}>
        <DefaultAddress.Provider value={[defaultAddres, setDefaultAddres]}>
          <UseReload.Provider value={[reload, setReload]}>
            <UserProfile.Provider value={[profile, setProfile]}>
              <UserContext.Provider value={[cartItems, setCartItems]}>
                <UserNotification.Provider
                  value={[notification, setNotification]}
                >
                  <SchedulePickUp.Provider value={[schedule, setSchedule]}>
                    <UserLocation.Provider
                      value={[targetLocation, setTargetLocation]}
                    >
                      {isMobile && IsAppMode ? <AppMode /> : <WebMode />}
                    </UserLocation.Provider>
                  </SchedulePickUp.Provider>
                </UserNotification.Provider>
              </UserContext.Provider>
            </UserProfile.Provider>
          </UseReload.Provider>
        </DefaultAddress.Provider>
      </UseActiveTabShow.Provider>
    </IonApp>
  );
};

export default App;
