import React, { useState } from "react";
import HeaderLocal from '../../Header/HeaderLocal';
import "./LocalRate.css";
import LocalRateItem from "./LocalRateItem";

const LocalRate: React.FC = () => {
  const [addItems, setAddItems] = useState<any>({});
  const [filterDate, setFilterData] = useState("");

  return (
    <div className="local_rate_div">
      <HeaderLocal setFilterData={setFilterData} show={{ul:true,location:false,search:true,profile:false}} />
      <a href="/local-schedule-pickup">
        <button className="schedule-pickup-btn">Schedule a Pickup</button>
      </a>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <LocalRateItem
              popUp={true}
              setAddItems={setAddItems}
              filterData={[filterDate, setFilterData]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalRate;
