import React, { useEffect } from "react";
import "./About.css";
import { IonCol, IonContent, IonGrid, IonHeader, IonRow } from "@ionic/react";
import Title from "../Title";
import { useHistory } from "react-router-dom";
import SideBar from "../WebView/SideBar/SideBar";
import { userTabClick } from "../../Events";

interface ContainerProps {
  Header: boolean;
}

const About: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  useEffect(() => {
    userTabClick(window.location.pathname);
  }, []);

  return (
    <>
      <IonHeader id="ion_header" className="d-flex-pickupdetails">
        <img
          onClick={() => history.goBack()}
          src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
          alt="Left Location"
        />
        <p onClick={() => history.goBack()}>Back</p>
      </IonHeader>

      <IonContent>
        <Title title="About" />
        <IonGrid className="style-2">
          <IonRow>
            <IonCol className="mb-5 style-2">
              <p className="description">
                ScrapUncle is a startup with a vision to divert recyclables from
                India's unsanitary landfills. We provide digital platforms
                (Application/Website) where users can schedule a doorstep pickup
                for their recyclables and get paid for them.
              </p>
              <h4 className="sub_title">Why Us</h4>
              <div className="description left_side">
                <h6 className="mb-1 sub_title_h6 trust">
                  Trust{" "}
                  <img
                    className="description_icon"
                    src="https://ik.imagekit.io/y8s2vhk66ef/handshake_f1x_j92b3.png"
                    alt=""
                  />
                </h6>
                We use ISO marked weighing machine and guarantee 100% accuracy
                in weight measurement
              </div>
              <div className="description text-right right_side">
                <h6 className="mb-1 sub_title_h6 left">
                  {" "}
                  <img
                    className="description_icon"
                    src="https://ik.imagekit.io/y8s2vhk66ef/check_lv9L0RUUQ.png"
                    alt=""
                  />{" "}
                  Convenience
                </h6>
                Schedule Pickup according to time and date convenient to you.
              </div>
              <div className="description left_side">
                <h6 className="mb-1 sub_title_h6">
                  Time efficiency{" "}
                  <img
                    className="description_icon"
                    src="https://ik.imagekit.io/y8s2vhk66ef/time_pmAx90696.png"
                    alt=""
                  />
                </h6>
                If you don't want cash then you can also shop quality products
                at reasonable prices from our online store in exchange of your
                scrap and save your shopping time.
              </div>
              <div className="description text-right right_side">
                <h6 className="mb-1 sub_title_h6 left">
                  {" "}
                  <img
                    className="description_icon"
                    src="https://ik.imagekit.io/y8s2vhk66ef/rupee_yddjB-Osa.png"
                    alt=""
                  />{" "}
                  Best Rates
                </h6>
                We try our best to provide you high worth of your scrap
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <SideBar />
    </>
  );
};

export default About;
