import React, { useContext, useEffect, useState } from "react";
import "./UpcomingPickupDetails.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import ShowModal from "../PageTwo/PageTwoContent/ShowModal";
import { userTabClick } from "../../Events";

const CartItems: React.FC = () => {
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState({});
  const history = useHistory();
  const [itemAdded, setItemAdded] = useState(false);
  const EditBtn = (item: any) => {
    setItems(item);
    setOpen(!open);
  };

  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    userTabClick(window.location.pathname);
  }, []);
  return (
    <IonPage>
      {open === true && (
        <ShowModal
          setItemAdded={setItemAdded}
          edit={true}
          open={[open, setOpen]}
          item={items}
        />
      )}

      <IonHeader className="d-flex-pickupdetails">
        <img
          onClick={goBack}
          src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
          alt=""
        />
        <p onClick={goBack}>Back</p>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h3 className="upcoming_pickup_detail_title">Your Cart</h3>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="blockTwoPage ">
                <Link to="/additem">
                  <div className="block_box">
                    <div className="plus_image">
                      <div className="plus">+</div>
                      <img src="" alt="" />
                    </div>
                    <div className="title_rate">
                      <h3 className="add_more_item">
                        Add More <br></br> Items
                      </h3>
                    </div>
                  </div>
                </Link>

                {cartItems.map((item: any) => (
                  <div key={item.id} className="block_box  d-nones">
                    <div className="plus_image">
                      <div onClick={() => EditBtn(item)} className="plus edit">
                        EDIT
                      </div>

                      <img
                        src={`https://swapeco.com/${item.img}`}
                        alt={"icon"}
                      />
                    </div>
                    <div className="title_rate">
                      <p>{item.title}</p>
                      <p>
                        RS {item.rate}/
                        {item.m_unit === "null" ? "KG" : item.m_unit}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <img
                className="upcomingDetainlsImg"
                src="https://ik.imagekit.io/y8s2vhk66ef/upcoming-pickup-details_RN6G24ZavY.png"
                alt=""
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <Link to="/schedulefill">
          <IonButton
            className="bottom_Btn"
            color="success"
            expand="full"
            id="bottom_btn"
          >
            Continue to schedule pickup
          </IonButton>
        </Link>
      </IonContent>
    </IonPage>
  );
};

export default CartItems;
