import { IonButton, IonSpinner, IonToast } from "@ionic/react";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router";
import "./LocalLogin.css";
import ApiRequest from "Services/Api.Service";
import useLocalStorage from "Services/useLocalStorage";
import { UserProfile } from "App";

type Inputs = {
  example: string;
  exampleRequired: string;
  firstName: string;
  lastName: string;
  email: string;
  isDeveloper: boolean;
};

interface Container {
  loginShow: any;
}

const FirstLoginView = (props: any) => {

  const history = useHistory();

  const {
    user,
    onSuccess
  } = props;

  const { register, handleSubmit } = useForm();


  const [selectCategory, setSelectCategory] = useState("household");

  const [err, setErr] = useState({ status: false, message: "" });
  const [loadingSpin, setLoadingSping] = useState(false)

  const onSubmit = (data: any) => {
    const totalData = { ...data, category: selectCategory };

    setLoadingSping(true);
    ApiRequest("PUT", "/profile/", {
      isAuthenticated: false,
      token: user.token,
      body: totalData,
    }).then((res) => {

      setLoadingSping(false);
      if (res.success === true) {

        // if (selectCategory === "household") {
        //   history.push("/home");
        // }
        // if (selectCategory === "enterprise") {
        //   history.push("/bussinessDetails");
        // }
        // if (selectCategory === "pickup_agent") {
        //   history.push("/home");
        // }
        onSuccess()
      } else {
        setErr({ status: true, message: res.message });
      }
    });
  };

  const onInputChange = (e:any) => {
    const elName = e.target.name;
    const elValue = e.target.value;

    if(elValue && elValue.length > 0){
      document.querySelector(`[for='${elName}']`)?.classList.add("Active")
    }else{
      document.querySelector(`[for='${elName}']`)?.classList.remove("Active")
    }
    console.log({elName})
  }
  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="webview_number_input_box">
          <div id="float-label">
            <input
              ref={register}
              type="text"
              onChange={onInputChange}
              maxLength={64}
              name="name"
              required
            />

            <label htmlFor="name">
              Name
            </label>
          </div>
        </div>

        <div className="webview_number_input_box last_child sign_up_middle_child">
          <div id="float-label">
            <input
              ref={register}
              onChange={onInputChange}
              type="text"
              name="pincode"
              required
            />
            <label htmlFor="pincode">
              Area PinCode
            </label>
          </div>
        </div>
        <div className="webview_number_input_box sign_up_middle_child">
          <div id="float-label">
            <input
              ref={register}
              onChange={onInputChange}
              type="text"
              name="email"
            />

            <label htmlFor="email">
              Email (Optional)
            </label>
          </div>
        </div>
        <div className="category">
          <h6>CATEGORY THAT BEST DESCRIBES YOU?</h6>
          <div className="category_box">
            <div className="category_box_box">
              <img
                className={
                  selectCategory === "household"
                    ? "selected_icon"
                    : "non_selected_icon"
                }
                onClick={() => setSelectCategory("household")}
                src="https://ik.imagekit.io/y8s2vhk66ef/household_coloured1__R95zz9GH.png"
                alt=""
              />
              <p
                className={
                  selectCategory === "household"
                    ? "selected_text"
                    : "non_selected_text"
                }
              >
                HOUSEHOLD
              </p>
            </div>
            <div className="category_box_box">
              <img
                className={
                  selectCategory === "enterprise"
                    ? "selected_icon"
                    : "non_selected_icon"
                }
                onClick={() => setSelectCategory("enterprise")}
                src="https://ik.imagekit.io/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                alt=""
              />
              <p
                className={
                  selectCategory === "enterprise"
                    ? "selected_text"
                    : "non_selected_text"
                }
              >
                ENTERPRISE
              </p>
            </div>
            {/* <div className="category_box_box">
              <img
                className={
                  selectCategory === "pickup_agent"
                    ? "selected_icon"
                    : "non_selected_icon"
                }
                onClick={() => setSelectCategory("pickup_agent")}
                src="https://ik.imagekit.io/y8s2vhk66ef/enterprise_bw1_RZxZ80dRv.png"
                alt=""
              />
              <p
                className={
                  selectCategory === "pickup_agent"
                    ? "selected_text"
                    : "non_selected_text"
                }
              >
                RECYCLING HERO
              </p>
            </div> */}
          </div>
        </div>
        {err.status === true && (
          <p className="sign_resend_code invalid">{err.message}</p>
        )}

        <IonButton className="login_btn_web_view" color="success" type="submit">
          {loadingSpin ? <IonSpinner name="lines" /> : "Next"}
        </IonButton>
      </form>
    </>
  )
}

const LocalLogin: React.FC<Container> = (props: any) => {
  const [loginShow, setLoginShow] = props.loginShow;
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [stateOtp, setStateOtp] = useState({ otp: "" });
  const [loadingSpin, setLoadingSping] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm<Inputs>();
  const history = useHistory();
  const [error, setError] = useState<any>("");
  const [numberLength, setNumberLength] = useState("");
  const [phoneNumberLength, setPhoneNumberLength] = useState<any>(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [resendCodeStop, setResendCodeStop] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [timeOut, setTimeOut] = useState(false);
  const [inValidOtp, setInValidOtp] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [oldUserContent, setOldUserContent] = useState<any>({});

  //Login FOrm SUbmit
  const onSubmit = (data: any) => {
    console.log(data);
    const phoneNumber = data.phoneNumber;
    const phoneOtp = { phone: phoneNumber };
    setLoadingSping(true);
    if (numberLength.length >= 10) {
      ApiRequest("POST", "auth/send", { body: phoneOtp })
        .then((data) => {
          console.log({ data });
          if (data.success) {
            setLoadingSping(false);
            setLoginShow("otp");
            setPhoneNumber(phoneNumber);
          } else {
            setError("Enter a valid Phone Number. Number should be 10 digit!");
            setLoadingSping(false);
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    } else {
      setError("Enter a valid Phone Number. Number should be 10 digit!");
      setLoadingSping(false);
    }
  };
  const numberLengthSet = (e: any) => {
    setNumberLength(e.target.value);
    setError("");
    const val = e.target.value;
    const valString = val.toString();
    const max = 10;

    if (valString.length <= max) {
      setPhoneNumberLength(Number(val));
    }
  };

  //OTP VALUE CHECK
  const handleChange = (otp: any) => {
    setStateOtp({ otp: otp });
    setInValidOtp(false);
  };
  // Resend code Auth
  const resendCode = () => {
    setMinutes(1);
    setResendCodeStop(resendCodeStop + 1);
    setTimeOut(false);
    const phoneOtp = { phone: phoneNumber };

    ApiRequest("POST", "/auth/re-send", { body: phoneOtp }).then((data) =>
      console.log()
    );
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds === 1 && minutes === 0) {
        setTimeOut(true);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  //OTP SUBMITED VALUE
  const onSubmitOtp = () => {
    console.log("click on otp submited");
    const codes = stateOtp.otp;
    const phoneCode = { phone: phoneNumber, code: codes };
    setLoadingSping(true);
    if (stateOtp.otp.length === 4) {
      ApiRequest("POST", "/auth/verify", { body: phoneCode }).then((data) => {
        console.log({ data });
        if (data.success === true) {
          ApiRequest("GET", "/profile/", {
            isAuthenticated: false,
            token: data.token,
          }).then((resData) => {
            console.log({ resData });
            if (resData.success === true) {

              const dataInfo = resData.data[0];
              setLoggedInUser({ ...loggedInUser, ...dataInfo, ...data });
              setProfile({ ...profile, ...dataInfo, ...data });
              setShowToast1(true);
              setLoadingSping(false);
              setInValidOtp(false);

              console.log("FIRST_LOGIN:", data)
              if (!data.first_login) {
                ApiRequest("GET", "app/home", {
                  isAuthenticated: false,
                  token: loggedInUser.token,
                }).then((data) => {
                  console.log({ getData: data });
                  setLoginShow("first_login");
                  setOldUserContent(data.data);
                });
              } else {
                setLoginShow("update_profile");
              }

            }
          });
        } else {
          setInValidOtp(true);
          setLoadingSping(false);
        }
      });
    } else {
      setInValidOtp(true);
      setLoadingSping(false);
    }
  };

  //view pickup function
  const viewPickUp = (name: string) => {
    if (name === "viewPickup") {
      history.push("/local-pickup-view");
    } else if (name === "schedulePickup") {
      history.push("/local-schedule-pickup");
    }
  };

  useEffect(() => {
    if (loggedInUser.token || loggedInUser.name) {
      ApiRequest("GET", "app/home", {
        isAuthenticated: false,
        token: loggedInUser.token,
      }).then((data) => {
        console.log({ getData: data });
        setLoginShow("first_login");
        setOldUserContent(data.data);
      });
    }
  }, []);
  return (
    <div
      className={
        loginShow === "first_login"
          ? "local_login_form first_login_form"
          : "local_login_form"
      }
    >
      {/* Phone Number Login */}
      {loginShow === "phone" && (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="local_login_title">Schedule a Pickup</h4>
            <p className="local_mobile_text">Mobile</p>
            <div className="local_input_box">
              <span>+91</span>
              <input
                ref={register}
                value={phoneNumberLength === 0 ? false : phoneNumberLength}
                onChange={(e: any) => numberLengthSet(e)}
                className="sign_header_input"
                type="number"
                name="phoneNumber"
                id="otp"
                autoComplete="new-password"
              />
            </div>
            <p className="error_para">{error} </p>
            <IonButton type="submit" className="local_next_button">
              {" "}
              {loadingSpin ? <IonSpinner name="lines" /> : " NEXT "}
            </IonButton>
          </form>
        </div>
      )}
      {/* OTP Varification */}
      {loginShow === "otp" && (
        <div>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="Login Successfully"
            duration={700}
          />
          <form onSubmit={handleSubmit(onSubmitOtp)}>
            <h4 className="local_login_title">Enter OTP</h4>
            <p className="local_mobile_text">OTP</p>

            <OtpInput
              containerStyle="otpInput_main_div"
              value={stateOtp.otp}
              onChange={handleChange}
              inputStyle="input_get_otp"
              numInputs={4}
              isInputNum={true}
              shouldAutoFocus={true}
            />

            {inValidOtp && (
              <p className="sign_resend_code invalid">
                Invalid OTP Entered.
              </p>
            )}
            {resendCodeStop === 3 ? (
              <p className="check_inbox">
                {/* Check your Inbox! */}
              </p>
            ) : timeOut ? (
              <p onClick={resendCode} className="receive_otp">
                Didn’t receive OTP? <span> Resend</span>
              </p>
            ) : (
              <>
                <p className="receive_otp">
                  No code? Resend after
                  {minutes === 0 && seconds === 0 ? null : (
                    <>
                      {" "}
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </>
                  )}
                </p>
                <p className="help_text">
                  Facing Problems? Call us at <a href="tel:+918595358613">+918595358613</a>
                </p>
              </>
            )}
            <IonButton type="submit" className="local_next_button">
              {" "}
              {loadingSpin ? <IonSpinner name="lines" /> : " NEXT "}
            </IonButton>
          </form>
        </div>
      )}
      {/* First Login View */}
      {loginShow === "update_profile"  && (
        <FirstLoginView 
        user={loggedInUser}
        onSuccess={()=>{
          console.log("Profile Updated")
          setLoginShow("first_login")
        }}
        />
      )}
      {loginShow === "first_login" && (
        <div className="old_user_div">
          <h4 className="local_login_title">Nice to see you, {(profile && profile.name) || (loggedInUser && loggedInUser.name)}!</h4>
          <p className="receive_otp mt-3">Impact made by Scapuncle</p>
          <div className="old_user_img">
            <div className="first_login_kg">
              <span className="lcd">
                {oldUserContent &&
                  oldUserContent.impact &&
                  oldUserContent.impact.recycled || "604015 kg"}
              </span>
              <span>Recycled</span>
            </div>
            <div className="first_login_euro hidden">
              {oldUserContent &&
                oldUserContent.impact &&
                oldUserContent.impact.earned}
              <span>Earned</span>
            </div>
            <img
              src="https://ik.imagekit.io/y8s2vhk66ef/Main_Page_Illustration_e9Jz4TCH361.png?updatedAt=1628127523534"
              alt=""
            />
            <p className="receive_otp">Wish to recycle more? </p>
          </div>
          <div className="button_div">
            <IonButton
              onClick={() => viewPickUp("schedulePickup")}
              className="local_next_button old_user_button pickupBtn"
            >
              Schedule a pickup
            </IonButton>
            <IonButton
              onClick={() => viewPickUp("viewPickup")}
              className="mt-3 local_next_button old_user_button pickupBtn"
            >
              Check my pickups
            </IonButton>

          </div>
          <a href="#"
            style={{ fontWeight: 700, fontSize: "12px", textAlign: "center" }}
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}>Logout</a>
        </div>
      )}
    </div>
  );
};

export default LocalLogin;
