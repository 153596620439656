import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import styled from "styled-components";
import UpcomingPickup from "../../Pickup/UpcomingPickup";
import SideBar from "../SideBar/SideBar";
import WebHeader from "../WebHeader/WebHeader";
import "./PickupsWebView.css";
import dayjs from "dayjs";
import CompletePickup from "../../Pickup/CompletePickup";
const Div = styled.div`
  padding: 0px;
`;

const DivFlex = styled.div`
  display: flex;
`;

const PickupsWebView: React.FC = () => {
  const [filterDate, setFilterData] = useState("");
  const [activePage, setActivePage] = useState("upcoming");
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [addItems, setAddItems] = useState<any>({});
  const [clickPickupToShow, setClickPickupToShow] = useState<any>({});
  const [value, onChange] = useState<any>(new Date());
  const [sideBarOpen, setSideBarOpen] = useState(false);

  useEffect(() => {
    switch (activePage) {
      case "upcoming":
        setActivePage("upcoming");
        break;

      case "complete":
        setActivePage("complete");
        break;

      default:
        break;
    }
  }, [activePage]);
  const month_name = function (dt: any) {
    const mlist: any = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return mlist[dt];
  };
  const dayName = (daycount: any) => {
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    return weekdays[daycount];
  };

  const pickUpBtnClick = (info: any) => {
    setActivePage(info);
    setClickPickupToShow({});
  };
  useEffect(() => {
    onChange(
      clickPickupToShow.pickup_date &&
        dayjs(clickPickupToShow.pickup_date).toDate()
    );
  }, [clickPickupToShow]);

  return (
    <div>
      <WebHeader
        sideBarOpen={[sideBarOpen, setSideBarOpen]}
        setAddItems={setAddItems}
        filterData={[filterDate, setFilterData]}
      />
      <div
        className={
          JSON.stringify(clickPickupToShow) === "{}"
            ? "web_view_main_content upcoming_pickup_content"
            : "web_view_main_content commplete_pickup_content"
        }
      >
        <SideBar />
        <Div className="web_view_upcoming_picksup web_view_top">
          <div className="pickup_upcoming style-2">
            <IonGrid>
              <IonRow>
                <IonCol className="upcming hook">
                  <IonButton
                    onClick={() => pickUpBtnClick("upcoming")}
                    className="pickup_btn"
                    color={activePage === "upcoming" ? "success" : "medium"}
                  >
                    Upcoming
                  </IonButton>
                  <IonButton
                    onClick={() => pickUpBtnClick("complete")}
                    className="pickup_btn"
                    color={activePage === "complete" ? "success" : "medium"}
                  >
                    Completed
                  </IonButton>
                </IonCol>
              </IonRow>
              {activePage === "upcoming" ? (
                <>
                  {/* <IonRow>
                    <Calendar
                      className="calender"
                      onChange={onChange}
                      value={value}
                    />
                  </IonRow> */}
                  <UpcomingPickup setClickPickupToShow={setClickPickupToShow} />
                </>
              ) : (
                <div>
                  <IonRow>
                    <div
                      className="text-icon cursor-pointer"
                      onClickCapture={() => setCalenderOpen(!calenderOpen)}
                    >
                      <div className="calender_text">
                        <img
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_calendar_outline_1__CvL7OMkvn.png"
                          alt=""
                        />
                        <p>Show Calender</p>
                      </div>
                      <img
                        className="images"
                        src="https://ik.imagekit.io/y8s2vhk66ef/Vector705_Stroke__GnGbJT83e.png"
                        alt=""
                      />
                    </div>
                  </IonRow>
                  {calenderOpen && (
                    <IonRow>
                      <Calendar
                        className="calender"
                        defaultActiveStartDate={
                          clickPickupToShow.upcoming_pickup &&
                          dayjs(
                            clickPickupToShow.upcoming_pickup.pickup_date
                          ).toDate()
                        }
                        value={value}
                      />
                    </IonRow>
                  )}
                  <CompletePickup setClickPickupToShow={setClickPickupToShow} />
                  {/* <IonRow>
                    <p className="upcoming_sub_title">
                      Processing <img src={reload} alt="" />
                    </p>
                    <p className="allupLoad">
                      All uploaded documents have been processed!
                    </p>
                  </IonRow>
                  <IonRow>
                    <p className="upcoming_sub_title">
                      Completed <img src={circle} alt="" />
                    </p>
                    <div
                      className="d-flex web_view_box"
                    
                    >
                      <div className="date">
                        <h3>MON</h3>
                        <b > 03</b>
                      </div>

                      <div className="location">
                        <p > 12:30 PM</p>
                        <p >
                          {" "}
                          123 LOCATION ST. DELHI...
                        </p>
                        <span >
                          News Paper, Plastic Bottles, +3 more
                        </span>
                      </div>
                    </div>{" "}
                    <div
                      className="d-flex web_view_box"
                    
                    >
                      <div className="date">
                        <h3>MON</h3>
                        <b > 03</b>
                      </div>

                      <div className="location">
                        <p> 12:30 PM</p>
                        <p >
                          {" "}
                          123 LOCATION ST. DELHI...
                        </p>
                        <span >
                          News Paper, Plastic Bottles, +3 more
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex web_view_box"
                     
                    >
                      <div className="date">
                        <h3 >MON</h3>
                        <b > 03</b>
                      </div>

                      <div className="location">
                        <p > 12:30 PM</p>
                        <p >
                          {" "}
                          123 LOCATION ST. DELHI...
                        </p>
                        <span >
                          News Paper, Plastic Bottles, +3 more
                        </span>
                      </div>
                    </div>
                  </IonRow> */}
                </div>
              )}
            </IonGrid>
          </div>
        </Div>
        <div className="web_view_impact_content web_view_top">
          {JSON.stringify(clickPickupToShow) !== "{}" && (
            <div className="pickups_summery_details style-2">
              <div className="date_title">
                <div className="date w-10">
                  <h2 className="mb-0">
                    {dayjs(clickPickupToShow && clickPickupToShow.pickup_date)
                      .toDate()
                      .toString()
                      .slice(0, 3)}
                  </h2>

                  <b>
                    {dayjs(
                      clickPickupToShow && clickPickupToShow.pickup_date
                    ).date() < 10 && "0"}
                    {dayjs(
                      clickPickupToShow && clickPickupToShow.pickup_date
                    ).date()}
                  </b>
                </div>
                <div className="title">
                  <h2>Pickup Summery</h2>
                </div>
              </div>
              <div className="status_recyclingHero">
                <div className="status">
                  <DivFlex>
                    <p className="status_name">Status:</p>
                    <p className="status_value">
                      {activePage === "upcoming"
                        ? clickPickupToShow.invoice &&
                          clickPickupToShow.invoice.status
                        : "Invoice not Uploaded"}
                    </p>
                  </DivFlex>
                  <DivFlex>
                    <p className="status_name">Date:</p>
                    <p className="status_value">
                      {dayName(
                        dayjs(
                          clickPickupToShow && clickPickupToShow.pickup_date
                        )
                          .toDate()
                          .getDay()
                      )}
                      ,
                      {month_name(
                        dayjs(
                          clickPickupToShow && clickPickupToShow.pickup_date
                        )
                          .toDate()
                          .getMonth()
                      )}
                      {` ${dayjs(clickPickupToShow.pickup_date)
                        .toDate()
                        .getDate()}`}
                    </p>
                  </DivFlex>
                  <DivFlex>
                    <p className="status_name">Time:</p>
                    <p className="status_value">
                      {clickPickupToShow.pickup_time &&
                        clickPickupToShow.pickup_time.slice(0, 15)}
                    </p>
                  </DivFlex>
                  <DivFlex>
                    <p className="status_name">Location:</p>
                    <p className="status_value">
                      {clickPickupToShow.address &&
                        clickPickupToShow.address.line1}
                    </p>
                  </DivFlex>
                </div>

                {clickPickupToShow.pickup_agent &&
                clickPickupToShow.pickup_agent.status === "ASSIGNED" ? (
                  <div className="recyclingHero">
                    <h4>Recyling Hero:</h4>
                    {clickPickupToShow.pickup_agent &&
                    clickPickupToShow.pickup_agent.details.photo ? (
                      <img
                        className="profile_picture"
                        src={
                          clickPickupToShow.pickup_agent &&
                          clickPickupToShow.pickup_agent.details.photo
                        }
                        alt="img"
                      />
                    ) : (
                      <img
                        className="profile_picture"
                        src="https://ik.imagekit.io/y8s2vhk66ef/Vendor_ProfilePic_ei3Uvln71.png"
                        alt="img"
                      />
                    )}

                    <h4>
                      {clickPickupToShow.pickup_agent &&
                        clickPickupToShow.pickup_agent.details.name}
                    </h4>
                    <h4 className="pickup_agent_mobile_number">
                      {clickPickupToShow.pickup_agent &&
                        clickPickupToShow.pickup_agent.details.mobile}
                    </h4>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="recyclables">
                <p className="recylables_p">Recyclables</p>
                {clickPickupToShow.items &&
                  clickPickupToShow.items.map((item: any) => (
                    <div key={item.id} className="block_box pickup_block_box">
                      <div className="plus_image">
                        <img src={item.image} alt={"icon"} />
                      </div>
                      <div className="title_rate">
                        <p>{item.title}</p>
                        <p>
                          {item.rate.amount}/{item.rate.unit}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="recyclables">
                <p className="recylables_p">Photos</p>
                <DivFlex>
                  <img
                    className="cardScrap"
                    src="https://ik.imagekit.io/y8s2vhk66ef/BG_vdvS81dnZ.png"
                    alt="cardscrap"
                  />
                  <img
                    className="cardScrap"
                    src="https://ik.imagekit.io/y8s2vhk66ef/upload1_ic6C3ewqU.png"
                    alt="cardscrap"
                  />
                </DivFlex>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PickupsWebView;
