import { PickerColumn } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonList,
  IonPicker,
  IonRow,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  SchedulePickUp,
  UserLocation,
  UserContext,
  UseReload,
} from "../../App";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import ShowModal from "../PageTwo/PageTwoContent/ShowModal";
import MultipleImageUpload from "./MultipleImageUpload";
import "./ScheduleFill.css";
import uploads from "./uploads";
import { trashOutline, createOutline } from "ionicons/icons";
import { schedulePickUpSuccessuflly } from "../../Events";
import Config from "../../Config";
import Skeleton from "Components/Skeleton";

interface ContainerProps {
  manuBar: boolean;
  sideBarOpen: any;
}

const WEIGHT_RANGE = [
  {
    label:'Less than 20 Kg',
    value: "Less than 20 Kg",
  },
  {
    label:'20-50 Kg',
    value: "20-50 Kg",
  },
  {
    label:'50-100 Kg',
    value: "50-100 Kg",
  },
  {
    label:'100-700 Kg',
    value: "100-700 Kg",
  },
  {
    label:'More than 700 Kg',
    value: "More than 700 Kg",
  },
]


const ScheduleFill: React.FC<ContainerProps> = (props) => {
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const [schedule, setSchedule] = useContext<any>(SchedulePickUp);
  const [openTrue, setOpenTrue] = useState<any>(false);
  const [week, setWeek] = useState<any>("Week");
  const [weekNums, setWeekNums] = useState<any>(2);
  const [isActive, setIsActive] = useState({ activeId: null });
  const [homeActive, setHomeActive] = useState(false);
  const [cartActive, setCartActive] = useState(false);
  const [dateActive, setDateActive] = useState(false);
  const [timeActive, setTimeActive] = useState(false);
  const [repeatActive, setRepeatActive] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlertForWeight, setShowAlertForWeight] = useState(false)

  let tomorrowDate: Date = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const [value, onChange] = useState<any>(tomorrowDate);
  const [selectTime, setSelectTime] = useState("12:00PM - 2:00PM");
  const [selectWeight, setSelectWeight] = useState("1 - 10 KG");

  const history = useHistory();
  const [file, setFile] = useState([]);
  const [loggedInUser] = useLocalStorage<any>("LoginUser", "");
  const [address, setAddress] = useState<any>({ status: false, fullData: [] });
  const [uploadPicLink, setUploadPicLink] = useState<any>([]);
  const [successPickup, setSuccessPickup] = useState<any>({
    status: false,
    message: "",
  });
  const [reload, setReload] = useContext<any>(UseReload);
  const [selectDate, setSelectDate] = useState("");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [err, setErr] = useState({ status: false, massage: "" });
  const [itemAdded, setItemAdded] = useState(false);
  const [loadingSpin, setLoadingSping] = useState(false);
  const [sideBarOpen, setSideBarOpen] = props.sideBarOpen;
  const location = useLocation();
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);

  useEffect(() => {
    ApiRequest("GET", "/address/", {
      isAuthenticated: false,
      token: loggedInUser.token,
    }).then((data) => {
      if (data.success) {
        setAddress({ fullData: data.data, status: data.success });
      }
    });
  }, [reload.address]);

  useEffect(() => {
    setSchedule({ ...schedule, photoes: file });
  }, [file]);

  useEffect(() => {
    if (value) {
      const year = value.getFullYear();
      const month = value.getMonth();
      const monthName = (mon: any) => {
        const monthNam = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return monthNam[mon];
      };

      const date = value.getDate();
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dayName = days[value.getDay()];

      setSelectDate(monthName(month));
      setSchedule({
        ...schedule,
        date: { year: year, month: month + 1, date: date },
        time: { ...schedule.time, day: dayName },
      });
    } else {
      const date: any = value;
      setSchedule({
        ...schedule,
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          date: date.getDate(),
        },
      });
    }
  }, [value, reload.schedule, successPickup.status]);
  console.log({ value });
  console.log({ schedule });
  const setWeight = (e: any) => {
    let el_value = e.target.getAttribute("data-value")
    if(el_value == "Less than 20 Kg"){
      setSchedule({ ...schedule, weight: null });
      return setShowAlertForWeight(true)
    }
    setSelectWeight(el_value);
    setSchedule({ ...schedule, weight: el_value });
  };

  const handleClick = (e: any) => {
    setSelectTime(`${e.target.innerText}`);
    const time = e.target.innerText;
    if (value !== "") {
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dayName = days[value.getDay()];
      setSchedule({ ...schedule, time: { title: time, day: dayName } });
    } else {
      setSchedule({ ...schedule, time: { title: time, day: "" } });
    }
  };

  const getFirstColumn = () => {
    return {
      name: "First",
      options: [{ text: "Week" }, { text: "Month" }, { text: "No Repeat" }],
    } as PickerColumn;
  };

  const openWeek = () => {
    if (openTrue === true) {
      setOpenTrue(false);
    } else {
      setOpenTrue(true);
    }
  };
  const weekNum = (e: any) => {
    const weeknum = e.target.value;
    setWeekNums(weeknum);
    setSchedule({ ...schedule, repeat: { title: week, number: weeknum } });
  };
  const addresss = (e: any, item: any) => {
    setIsActive({ activeId: item.id });
    setSchedule({ ...schedule, address: item });
  };
  const toggleClassHome = () => {
    setHomeActive(!homeActive);
  };
  const toggleClassCart = () => {
    setCartActive(!cartActive);
  };
  const toggleClassDate = () => {
    setDateActive(!dateActive);
  };
  const toggleClassRepeat = () => {
    setRepeatActive(!repeatActive);
  };
  const toggleClassTime = () => {
    setTimeActive(!timeActive);
  };
  const toggleClassUpload = () => {
    setUploadActive(!uploadActive);
  };

  const scheduleClick = (e: any) => {
    setLoadingSping(true);
    if (file.length) {
      //File Uploading on Imakekit server and get url
      var addMoreItems: any = document.getElementById("addMoreItem");
      const fileArry = [...addMoreItems?.files];
      var resultUrl: any = [];
      fileArry.map((item) =>
        uploads(e).upload(
          {
            file: item,
            fileName: item.name || "sample-file.jpg",
            tags: ["tag1"], // Comma seperated value of tags
            responseFields: "tags", // Comma seperated values of fields you want in response e.g. tags, isPrivateFile etc
          },
          (err: any, result: any) => {
            if (err) {
              setLoadingSping(false);
            } else {
              resultUrl = [...resultUrl, result.url];
              fileUploadLink();
            }
          }
        )
      );

      const fileUploadLink = () => {
        if (resultUrl.length === file.length) {
          setUploadPicLink(resultUrl);
          if (window.screen.availWidth > 992) {
            setLoadingSping(true);
            setTimeout(() => {
              formSubmit(resultUrl);
            }, 1400);
          } else {
            setShowAlert1(true);
          }
        } else {
        }
      };
    } else {
      if (window.screen.availWidth > 992) {
        setLoadingSping(true);
        setTimeout(() => {
          formSubmit(resultUrl);
        }, 1400);
      } else {
        setShowAlert1(true);
      }
    }
  };

  console.log({ schedule });
  const formSubmit = (resultUrl: any) => {
    const apiPost = (formData: any) => {
      ApiRequest("POST", "pickup/new", {
        isAuthenticated: false,
        token: loggedInUser.token,
        body: formData,
      })
        .then((res) => {
          if (res.success) {
            setIsActive({ activeId: null });
            setLoadingSping(false);
            schedulePickUpSuccessuflly(`${res.message}`);
            setSuccessPickup({ status: true, message: res.message });
            setSchedule({
              address: {},
              items: [],
              date: {},
              repeat: { title: "week", number: 2 },
              time: {},
            });
            setSelectWeight("1 - 10 KG");
            onChange(tomorrowDate);
            setCartItems([]);
            setReload({ ...reload, schedule: !reload.schedule });
            setTimeout(() => {
              history.push("/confirmationpickup");
            }, 2000);
          } else {
            setSuccessPickup({ status: true, message: res.message });
            setLoadingSping(false);
          }
        })
        .catch((err) => {
          setErr({ status: true, massage: err.message });
          setLoadingSping(false);
        });
    };

    if (window.screen.availWidth > 992) {
      const formData = {
        ...schedule,
        items: cartItems,
        photoes: resultUrl,
        message: "",
        weight: { title: "tv", value: "10", unit: "kg" },
      };

      apiPost(formData);
      setLoadingSping(true);
    } else {
      const formData = {
        ...schedule,
        items: cartItems,
        photoes: uploadPicLink,
        message: "",
        weight: { title: "tv", value: "10", unit: "kg" },
      };
      console.log({ formData });

      apiPost(formData);
      setLoadingSping(true);
    }

    setTimeout(() => {
      setSchedule({
        address: {},
        items: [],
        date: {},
        repeat: { title: "week", number: 2 },
        time: "",
        schedule: schedule,
      });
    }, 1400);
  };

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState({});

  const EditBtn = (item: any) => {
    if (window.screen.availWidth > 992) {
      history.push({
        pathname: "/additem",
        state: { ...item },
      });
    } else {
      setItems(item);
      setOpen(!open);
    }
  };

  const DeleteAddress = (item: any) => {
    ApiRequest("DELETE", `address/${item.id}`, {
      isAuthenticated: false,
      token: loggedInUser.token,
    })
      .then((res) => {
        setReload({ ...reload, address: !reload.address });
      })
      .catch((err) => setErr({ status: true, massage: err.message }));
  };

  const EditAddress = (item: any) => {
    const locationCenter = JSON.parse(item.location);

    setTargetLocation({
      ...item,
      ...locationCenter,
    });
    history.push({
      pathname: "/mapaddress",
      state: { pathname: location.pathname, action: "PUT" },
    });
  };
  const closeIcon_Item_click = (info: any) => {
    setCartItems(cartItems.filter((item: any) => item.id !== info));
  };
  const anotherAddress = () => {
    if (window.screen.availWidth > 992) {
      setSideBarOpen(true);
    } else {
      console.log("click another address");
      history.push({
        pathname: "/searchaddress",
        state: { pathname: location.pathname, action: "POST" },
      });
    }
  };

  const DeleteAddressClick = (item: any) => {
    if (window.screen.availWidth > 992) {
      DeleteAddress(item);
    } else {
      setDeleteAlert(true);
    }
  };

  return (
    <IonContent className="style-2">
      <IonToast
        isOpen={err.status}
        onDidDismiss={() => setErr({ ...err, status: false })}
        message={err.massage}
        duration={1000}
      />
      <IonToast
        isOpen={reload.status}
        onDidDismiss={() => setReload({ ...reload, address: false })}
        message={reload.massage}
        duration={1000}
      />
      <div className="style-2">
        <IonToast
          isOpen={successPickup.status}
          onDidDismiss={() => setSuccessPickup({ status: false, message: "" })}
          message="Pickup Successful"
          duration={700}
        />
        {open === true && (
          <ShowModal
            setItemAdded={setItemAdded}
            edit={true}
            open={[open, setOpen]}
            item={items}
          />
        )}
        <Accordion>
          <IonList>
            <div
              className={schedule.weight ? "item_inner complete" : "item_inner"}
            >
              <Accordion.Item eventKey="0">
                <div>
                  <Accordion.Header
                    className="accordion_btn"
                    onClick={toggleClassCart}
                  >
                    <div className="list_box">
                      <div className="list_first">
                        <IonImg
                          className="icon"
                          src={Config.EXTERNAL_IMAGES.ICONS.ESTIMATED_WEIGHT}
                          alt="cart Item"
                        />
                        <div className="title_error">
                          <h4 className="h4title">
                            {schedule.weight
                              ? `${schedule.weight}`
                              : "ESTIMATED WEIGHT"}
                          </h4>
                        </div>

                        {schedule.weight && (
                          <IonImg
                            className="checkIcon"
                            src={Config.EXTERNAL_IMAGES.ICONS.CHECK_ICON}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="optional_box">
                        <p className="optional_text">Optional</p>

                        <IonImg
                          className={
                            cartActive
                              ? `repeatArray list_arrow_icon`
                              : "list_arrow_icon"
                          }
                          src={Config.EXTERNAL_IMAGES.ICONS.ARROW_DOWN}
                          alt=""
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                </div>

                <Accordion.Body>
                  <div id="CartItems">
                    <IonGrid>
                      <IonRow>
                        {WEIGHT_RANGE.map((w,i)=>(
                          <IonCol key={i}  size="6" size-sm>
                            {" "}
                            <div
                              onClick={(e) => setWeight(e)}
                              data-value={w.value}
                              className={
                                selectWeight === w.value
                                  ? "normal_time active_time"
                                  : "normal_time"
                              }
                            >
                            {w.label}
                          </div>{" "}
                          </IonCol>
                        ))}
                      </IonRow>
                

                    </IonGrid>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div
              className={schedule.date ? "item_inner complete" : "item_inner"}
            >
              <Accordion.Item eventKey="1">
                <div>
                  <Accordion.Header
                    className="accordion_btn"
                    onClick={toggleClassCart}
                  >
                    <div className="list_box">
                      <div className="list_first">
                        <IonImg
                          className="icon"
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_calendar_solid_IFLsbfWZ7.png"
                          alt=""
                        />

                        <div className="title_error">
                          <h4 className="h4title">
                            {JSON.stringify(schedule.date) !== "{}"
                              ? `${schedule.time.day}, ${selectDate} ${schedule.date.date}, ${schedule.date.year}`
                              : "Date"}
                          </h4>
                        </div>
                        {schedule.date && (
                          <IonImg
                            className="checkIcon"
                            src={Config.EXTERNAL_IMAGES.ICONS.CHECK_ICON}
                            alt=""
                          />
                        )}
                      </div>
                      <div>
                        <IonImg
                          className={
                            dateActive
                              ? `dateArraw list_arrow_icon`
                              : "list_arrow_icon"
                          }
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <div id="dateActive">
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <Calendar
                            defaultActiveStartDate={tomorrowDate}
                            minDate={tomorrowDate}
                            onChange={onChange}
                            value={value ? value : null}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div
              className={
                schedule.time && schedule.time.title
                  ? "item_inner complete"
                  : "item_inner"
              }
            >
              <Accordion.Item eventKey="3">
                <div>
                  <Accordion.Header
                    className="accordion_btn"
                    onClick={toggleClassTime}
                  >
                    <div className="list_box">
                      <div className="list_first">
                        <IonImg
                          className="icon"
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_time_solid_pmmfzpC36z.png"
                          alt=""
                        />

                        <div className="title_error">
                          <h4 className="h4title">
                            {(schedule.time && schedule.time.title) || "Time"}
                          </h4>
                        </div>
                        {schedule.time.title && (
                          <IonImg
                            className="checkIcon"
                            src={Config.EXTERNAL_IMAGES.ICONS.CHECK_ICON}
                            alt=""
                          />
                        )}
                      </div>
                      <div>
                        <IonImg
                          className={
                            timeActive
                              ? "list_arrow_icon timeArray"
                              : ` list_arrow_icon`
                          }
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <div id="timeActive">
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          {" "}
                          <div
                            onClick={(e) => handleClick(e)}
                            className={
                              selectTime === "12:00PM - 2:00PM"
                                ? "normal_time active_time"
                                : "normal_time"
                            }
                          >
                            12:00PM - 2:00PM
                          </div>{" "}
                        </IonCol>
                        <IonCol>
                          {" "}
                          <div
                            onClick={(e) => handleClick(e)}
                            className={
                              selectTime === "2:00PM - 4:00PM"
                                ? "normal_time active_time"
                                : "normal_time"
                            }
                          >
                            2:00PM - 4:00PM
                          </div>{" "}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          {" "}
                          <div
                            onClick={(e) => handleClick(e)}
                            className={
                              selectTime === "4:00PM - 6:00PM"
                                ? "normal_time active_time"
                                : "normal_time"
                            }
                          >
                            4:00PM - 6:00PM
                          </div>{" "}
                        </IonCol>
                        <IonCol>
                          {" "}
                          <div
                            onClick={(e) => handleClick(e)}
                            className={
                              selectTime === "6:00PM - 8:00PM"
                                ? "normal_time active_time"
                                : "normal_time"
                            }
                          >
                            6:00PM - 8:00PM
                          </div>{" "}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          {" "}
                          <div
                            onClick={(e) => handleClick(e)}
                            className={
                              selectTime === "8:00PM - 10:00PM"
                                ? "normal_time active_time"
                                : "normal_time"
                            }
                          >
                            8:00PM - 10:00PM
                          </div>{" "}
                        </IonCol>
                        <IonCol>
                          {" "}
                          <div
                            onClick={(e) => handleClick(e)}
                            className={
                              selectTime === "10:00PM - 12:00PM"
                                ? "normal_time active_time"
                                : "normal_time"
                            }
                          >
                            10:00PM - 12:00PM
                          </div>{" "}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div className="item_inner complete">
              <Accordion.Item eventKey="4">
                <div>
                  <Accordion.Header
                    className="accordion_btn"
                    onClick={toggleClassRepeat}
                  >
                    <div className="list_box">
                      <div className="list_first">
                        <IonImg
                          className="icon"
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_repeat_outline_yvlYYqfw8.png"
                          alt=""
                        />

                        <h4 className="h4title">
                          {` Repeats ${
                            schedule && schedule.repeat.title === "No Repeat"
                              ? ""
                              : "Every" + schedule && schedule.repeat.number
                          }  ${schedule && schedule.repeat.title}`}
                        </h4>

                        <IonImg
                          className="checkIcon"
                          src={Config.EXTERNAL_IMAGES.ICONS.CHECK_ICON}
                          alt=""
                        />
                      </div>
                      <div className="optional_box">
                        <p className="optional_text">Optional</p>

                        <IonImg
                          className={
                            repeatActive
                              ? `repeatArray list_arrow_icon`
                              : "list_arrow_icon"
                          }
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <div id="repeatActive">
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="week">
                            {schedule.repeat.title === "No Repeat" ? null : (
                              <>
                                <h6>Every</h6>
                                <input
                                  onChange={(e) => weekNum(e)}
                                  className="weekTwo"
                                  type="number"
                                  defaultValue="2"
                                />
                              </>
                            )}

                            <div onClick={openWeek} className="d-flex-week">
                              <p className="mb-0">{week}</p>

                              <div className="week_picker">
                                <IonImg
                                  className={
                                    openTrue
                                      ? "list_arrow_icons "
                                      : ` list_arrow_icons repeat_icon`
                                  }
                                  src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png"
                                  alt=""
                                />

                                <IonPicker
                                  isOpen={openTrue}
                                  columns={[getFirstColumn()]}
                                  buttons={[
                                    {
                                      text: "Cancel",
                                      role: "cancel",
                                      handler: () => {
                                        setOpenTrue(false);
                                        setLoadingSping(false);
                                      },
                                    },
                                    {
                                      text: "Confirm",
                                      handler: (value) => {
                                        setOpenTrue(false);
                                        setWeek(value.First.text);
                                        setSchedule({
                                          ...schedule,
                                          repeat: {
                                            title: value.First.text,
                                            number: weekNums,
                                          },
                                        });
                                      },
                                    },
                                  ]}
                                ></IonPicker>
                              </div>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div
              className={
                JSON.stringify(schedule.address) !== "{}"
                  ? "item_inner complete"
                  : "item_inner"
              }
            >
              <Accordion.Item eventKey="5">
                <div>
                  <Accordion.Header
                    className="accordion_btn"
                    onClick={toggleClassHome}
                  >
                    <div className="list_box">
                      <div className="list_first">
                        <IonImg
                          className="icon"
                          src="https://ik.imagekit.io/y8s2vhk66ef/Subtract_UmDJYyQo2.png"
                          alt="Location"
                        />

                        <div className="title_error">
                          <h4 className="h4title">Pickup Address </h4>
                        </div>

                        {JSON.stringify(schedule.address) !== "{}" && (
                          <IonImg
                            className="checkIcon"
                            src={Config.EXTERNAL_IMAGES.ICONS.CHECK_ICON}
                            alt=""
                          />
                        )}
                      </div>
                      <div>
                        <IonImg
                          className={
                            homeActive
                              ? `homeArray list_arrow_icon`
                              : "list_arrow_icon"
                          }
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <div id="homeActive">
                    <IonGrid>
                      {address.status === false && <Skeleton />}

                      {address.status === true &&
                      JSON.stringify(address.fullData) !== "[]" ? (
                        address.fullData.map((item: any, index: number) => (
                          <IonRow key={index} className="address_item">
                            <IonCol>
                              <div
                                className={
                                  isActive && isActive.activeId === item.id
                                    ? `active`
                                    : ""
                                }
                                onClick={(e) => addresss(e, item)}
                                id="address_box"
                              >
                                <IonAlert
                                  isOpen={deleteAlert}
                                  onDidDismiss={() => setDeleteAlert(false)}
                                  cssClass="my-custom-class"
                                  header={"Are You Sure!"}
                                  mode="ios"
                                  buttons={[
                                    {
                                      text: "Cancel",
                                      role: "cancel",
                                    },
                                    {
                                      text: "Delete",
                                      cssClass: "secondary",
                                      handler: () => DeleteAddress(item),
                                    },
                                  ]}
                                />
                                <div className="title">
                                  <p className="font-weight-bold text-uppercase mt-0">
                                    {item.title}
                                  </p>
                                  <div className="adderss_icon">
                                    <span onClick={() => EditAddress(item)}>
                                      <IonIcon
                                        className="mr-2"
                                        icon={createOutline}
                                      ></IonIcon>
                                    </span>
                                    <span
                                      onClick={() => DeleteAddressClick(item)}
                                    >
                                      <IonIcon icon={trashOutline}></IonIcon>
                                    </span>
                                  </div>
                                </div>

                                <p>
                                  {item.line1} <br></br>
                                  {item.city}-{item.pincode}
                                </p>
                              </div>
                            </IonCol>
                          </IonRow>
                        ))
                      ) : (
                        <p className="text-center my-2">No Address Add Yet!</p>
                      )}
                      <IonRow>
                        <IonCol>
                          <div id="address_box">
                            <p
                              onClick={anotherAddress}
                              className="title cursor-pointer"
                            >
                              Add Another Address{" "}
                            </p>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div
              className={
                file && file.length !== 0 ? "item_inner complete" : "item_inner"
              }
            >
              <Accordion.Item eventKey="6">
                <div>
                  <Accordion.Header
                    className="accordion_btn"
                    onClick={toggleClassUpload}
                  >
                    <div className="list_box">
                      <div className="list_first">
                        <IonImg
                          className="icon"
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_upload_solid_1bx_SsdjH.png"
                          alt=""
                        />

                        <div className="title_error">
                          <h4 className="h4title">Upload </h4>
                        </div>
                        {file && file.length !== 0 && (
                          <IonImg
                            className="checkIcon"
                            src={Config.EXTERNAL_IMAGES.ICONS.CHECK_ICON}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="optional_box">
                        <p className="optional_text">Optional</p>
                        <IonImg
                          className={
                            uploadActive
                              ? `uploadArray list_arrow_icon`
                              : "list_arrow_icon"
                          }
                          src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_iO9QgVTOoX.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <div id="uploadActive">
                    <IonGrid>
                      <div>
                        <MultipleImageUpload
                          setFile={[file, setFile]}
                          show={false}
                        />
                      </div>
                    </IonGrid>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          </IonList>
        </Accordion>

        <div>
          <IonGrid>
            {JSON.stringify(schedule.address) !== "{}" &&
            JSON.stringify(schedule.date) !== "{}" &&
            JSON.stringify(schedule.repeat) !== "{}" &&
            Object.keys(schedule.time).length === 2 
            ?null
            : (
              <IonRow>
                <IonCol>
                  <div className='qustionMark_box'>
                    <img
                      src={Config.EXTERNAL_IMAGES.ICONS.QUESTION_MARK}
                      style={{ padding: "5px", margin: "0px 10px" }}
                      alt="questionMark"
                    />
                    <span className='questionMark_text'>
                      Please fill all the required details to <br />
                      schedule pickup
                    </span>
                  </div>
                </IonCol>
              </IonRow>
            ) }

            <IonRow>
              <IonCol>
                {loadingSpin ? (
                  <IonButton
                    className="schedule_btn"
                    onClick={(e) => scheduleClick(e)}
                    color={"success"}
                    expand="block"
                  >
                    <IonSpinner name="lines" />
                  </IonButton>
                ) : (
                  <IonButton
                    style={{ width: "100%" }}
                    className="schedule_btn"
                    onClick={(e) => scheduleClick(e)}
                    color={
                      JSON.stringify(schedule.address) !== "{}" &&
                      JSON.stringify(schedule.date) !== "{}" &&
                      JSON.stringify(schedule.repeat) !== "{}" &&
                      Object.keys(schedule.time).length === 2
                        ? "success"
                        : "medium"
                    }
                    expand="block"
                    disabled={
                      JSON.stringify(schedule.address) !== "{}" &&
                      JSON.stringify(schedule.date) !== "{}" &&
                      JSON.stringify(schedule.repeat) !== "{}" &&
                      Object.keys(schedule.time).length === 2
                        ? false
                        : true
                    }
                  >
                    Schedule pickup
                  </IonButton>
                )}
                <IonAlert
                  isOpen={showAlert1}
                  onDidDismiss={() => setShowAlert1(false)}
                  cssClass="my-custom-class"
                  header={"Confirm Pickup ?"}
                  mode="ios"
                  buttons={[
                    {
                      text: "Cancel",
                      role: "cancel",
                      handler: () => {
                        setOpenTrue(false);
                        setLoadingSping(false);
                      },
                    },
                    {
                      text: "Confirm",
                      cssClass: "secondary",
                      handler: (e: any) => formSubmit(e),
                    },
                  ]}
                />

                <IonAlert
                  isOpen={showAlertForWeight}
                  onDidDismiss={() => setShowAlertForWeight(false)}
                  // cssClass="my-custom-class"
                  header={""}
                  message={"We do not offer pick-up service for materials less than 15kgs. Kindly drop it off at the nearest drop-off point. Call us at +91-8595358613"}
                  buttons={[
                    {
                      text: "Okay",
                      role: "cancel",
                      handler: () => {
                        setShowAlertForWeight(false)
                      },
                    },
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </IonContent>
  );
};

export default ScheduleFill;
