import React, { useContext, useState, useEffect, useRef } from "react";
import "./WebHeader.css";
import { IonBadge, IonSearchbar, IonButton, IonIcon } from "@ionic/react";
import {
  UserContext,
  UserProfile,
  UserNotification,
  UserLocation,
  DefaultAddress,
} from "../../../App";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import useLocalStorage from "../../../Services/useLocalStorage";
import ApiRequest from "../../../Services/Api.Service";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationImportantOutlinedIcon from "@material-ui/icons/NotificationImportantOutlined";
import LocationSidebar from "./LocationSidebar";
import { closeCircleOutline } from "ionicons/icons";

interface ContainerProps {
  filterData: any;
  setAddItems: any;
  sideBarOpen: any;
}
// const color = ['red','green', 'pirple', 'yellow', 'tomato']

const WebHeader: React.FC<ContainerProps> = (props) => {
  const [searchText, setSearchText] = useState("");
  const [filterDate, setFilterData] = props.filterData;
  const [cartItems, setCartItems] = useContext<any>(UserContext);
  const [notification, setNotification] = useContext<any>(UserNotification);
  const [openCart, setOpenCart] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const [user, setUser] = useLocalStorage<any>("user", "");
  const location = useLocation();
  const locationPath = location.pathname;
  const { name } = loggedInUser;
  const wrapperRef = useRef<any>(null);
  const locationRef = useRef<any>(null);
  const history = useHistory();
  const [targetLocation, setTargetLocation] = useContext<any>(UserLocation);
  const [sideBarOpen, setSideBarOpen] = props.sideBarOpen;
  const [showItem, setShowItem] = useState<any>("search");
  const [position, setPosition] = useState();
  const [defaultAddres, setDefaultAddres] = useContext<any>(DefaultAddress);

  const getLocation = async () => {};
  // Close Cart Sidebar
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (locationRef && locationRef.current && !locationRef.current.contains(event.target)) {
        setSideBarOpen(false);
        setShowItem("search");
      }
      if (wrapperRef && !wrapperRef.current.contains(event.target)) {
        setOpenCart(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, locationRef]);

  useEffect(() => {
    setFilterData(searchText);
  }, [searchText]);

  const cartOpen = (title: string) => {
    setOpenCart(!openCart);
    if (title === "Cart") {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  };

  const editBtn = (item: any) => {
    props.setAddItems(item);
    setOpenCart(!openCart);
  };
  const addressClick = () => {
    const latLng = JSON.parse(loggedInUser.defaultAddress &&loggedInUser.defaultAddress.location);
    setTargetLocation({...loggedInUser.defaultAddress,...latLng});
    setSideBarOpen(true);
  };
  const webCartItemClose = (id: any) => {
    setCartItems(cartItems.filter((item: any) => item.id !== id));
  };

  return (
    <>
      <div className="web_header">
        <LocationSidebar
          getLocation={getLocation}
          position={position}
          showItem={[showItem, setShowItem]}
          locationRef={locationRef}
          sideBarOpen={[sideBarOpen, setSideBarOpen]}
        />
        <div className="logo_search">
          <Link className="header_logo" to="/home">
            <img
              className="web_view_logo"
              src="https://ik.imagekit.io/y8s2vhk66ef/Logo_Cb_0rAnnv.png"
              alt="Logo"
            />
          </Link>

          {JSON.stringify(defaultAddres) !== "{}" ? (
            <div className="two_section web">
              <p
                onClick={addressClick}
                className="margin_bottom_0 text-truncate"
              >
                <span className="text-uppercase font-weight-bold text-underline">
                  {defaultAddres.title}
                </span>
                , {defaultAddres.line1}
              </p>

              <img
                onClick={addressClick}
                className="arrow_iconss"
                src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_f_6e30JZp.png"
                alt=""
              />
            </div>
          ) : (
            <div onClick={addressClick} className="header-first web_title">
              <p>Select a Location</p>
              <img
                className="arrow_icon"
                src="https://ik.imagekit.io/y8s2vhk66ef/Icon_down_outline_f_6e30JZp.png"
                alt=""
              />
            </div>
          )}
          {locationPath === "/additem" && (
            <IonSearchbar
              className="search_bar"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
          )}
        </div>

        <div>
          <ul className="web_header_ul">
            <li className="cursor-pointer">
              {cartItems.length ? (
                <div className="cartIcon" onClick={() => cartOpen("Cart")}>
                  <img
                    src="https://ik.imagekit.io/y8s2vhk66ef/Icon_cart_solid_ch_un9ae6.png"
                    alt=""
                  />
                  <IonBadge className="badgeStyle" color="danger">
                    {cartItems.length}
                  </IonBadge>
                </div>
              ) : (
                <div className="cartIcon" onClick={() => cartOpen("Cart")}>
                  <img
                    src="https://ik.imagekit.io/y8s2vhk66ef/Icon_cart_solid_ch_un9ae6.png"
                    alt=""
                  />
                </div>
              )}
            </li>
            <li className="cursor-pointer">
              {notification.length ? (
                <div
                  className="cartIcon"
                  onClick={() => cartOpen("Notification")}
                >
                  <img
                    src="https://ik.imagekit.io/y8s2vhk66ef/Icon_notification_solid_ol-Fggv6z.png"
                    alt=""
                  />
                  <IonBadge className="badgeStyle" color="danger">
                    {notification.length}
                  </IonBadge>
                </div>
              ) : (
                <div
                  className="cartIcon"
                  onClick={() => cartOpen("Notification")}
                >
                  <img
                    src="https://ik.imagekit.io/y8s2vhk66ef/Icon_notification_solid_ol-Fggv6z.png"
                    alt=""
                  />
                </div>
              )}
            </li>
            <li>
              {" "}
              <Link to="/profile"> {(profile && profile.name) || name}</Link>
            </li>
            <Link to="/profile">
              <li className="cursor-pointer profile_image">
                {(profile.name && profile.name.charAt(0)) ||
                  (name && name.charAt(0))}
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div ref={wrapperRef} className={openCart ? "side_bar open" : "side_bar"}>
        <h2 className="display_flex p-3">
          <span>{showSidebar ? "Cart" : "Notification"}</span>
          <span className="cursor-pointer close-cart-icon">
            <CloseIcon onClick={() => setOpenCart(false)} />
          </span>
        </h2>

        {showSidebar === true ? (
          <ul
            className={`cart_ul style-2 ${
              showSidebar === true ? "" : "height-90"
            }`}
          >
            {cartItems.length === 0 ? (
              <>
                <img
                  className="emptyCartImage"
                  src="https://ik.imagekit.io/y8s2vhk66ef/emptyCart_Lkdbyn7CY.png"
                  alt="emptyCart"
                />
                <p className="text-center">There is no more Cart Items</p>
              </>
            ) : (
              cartItems.map((item: any, index: any) => (
                <li key={index} className="display_flex">
                  <img
                    className="item_pic"
                    src={`https://swapeco.com/${item.img}`}
                    alt=""
                  />
                  <div className="item_text">
                    <p>{item.title}</p>
                    <p className="kg">
                      {item.kg} {item.m_unit + " "} / {" " + item.rate}
                    </p>
                  </div>
                  <button onClick={() => editBtn(item)} className="edit_btn">
                    Edit
                  </button>
                  <IonIcon
                    onClick={() => webCartItemClose(item.id)}
                    className="cartIcon_close"
                    icon={closeCircleOutline}
                  />
                </li>
              ))
            )}
          </ul>
        ) : (
          <ul
            className={`cart_ul style-2 ${
              showSidebar === false ? "height-90" : ""
            }`}
          >
            {notification.length === 0 ? (
              <>
                <NotificationImportantOutlinedIcon className="notifyBell" />
                <p className="text-center">There is no more Notification </p>
              </>
            ) : (
              notification.map((item: any, index: any) => (
                <li key={index} className="display_flex">
                  <span className="mr-2">
                    <FiberManualRecordIcon style={{ color: "tomato" }} />
                  </span>
                  <div>
                    <p>{item.title}</p>
                    <p className="kg">5 {item.m_unit}</p>
                  </div>
                </li>
              ))
            )}
          </ul>
        )}
        {showSidebar === true && (
          <div id="continue_scheduling">
            {cartItems.length !== 0 && (
              <>
                <p className="">Payment estimate</p>
                <p className="price">
                  RS{" "}
                  {cartItems.reduce(function (prev: any, item: any) {
                    return prev + Number(item.rate) * Number(item.kg);
                  }, 0)}
                </p>
                <Link to="/schedulefill">
                  <IonButton
                    expand="block"
                    className="btn-schedule-pickup"
                    color="success"
                  >
                    Continue to scheduling
                  </IonButton>
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default WebHeader;
