import { IonCol, IonAlert, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserProfile } from "../../../App";
import ApiRequest from "../../../Services/Api.Service";
import ShowModal from "../../PageTwo/PageTwoContent/ShowModal";
import './LocalRate.css';
// import { UserProfile } from "../../../App";
// import ShowModal from "./ShowModal";

interface ContainerProps {
  filterData: any;
  setAddItems: any;
  popUp: boolean;
}

const LocalRateItem: React.FC<ContainerProps> = (props) => {
  const [profile, setProfile] = useContext<any>(UserProfile);
  const setAddItems = props.setAddItems;
  const [filterDate, setFilterData] = props.filterData;
  const [open, setOpen] = useState<any>(false);
  const [items, setItems] = useState({});
  const [totalData, setTotalData] = useState<any>([]);
  const [itemAdded, setItemAdded] = useState({ status: false, message: "" });
  const fetchData = () => {
    return ApiRequest(
      "GET",
      "/app/rate-list",
      profile && { token: profile.token }
    );
  };
  const { data, status } = useQuery("rents", fetchData);

  const plusClick = (item: any) => {
    if (props.popUp) {
      setOpen(!open);
      setItems(item);
    }
    setAddItems(item);
  };

  const searchData = totalData.filter((val: any) => {
    if (val == "") {
      return val;
    } else if (val.title.toLowerCase().includes(filterDate.toLowerCase())) {
      return val;
    }
  });

  useEffect(() => {
    let child: any = [];

    if (status === "success") {
      data.data.map((item: any) => (child = [...child, ...item.children]));
    }

    setTotalData(child);
  }, [filterDate]);

  // useEffect(()=>{
  //   let child:any = [];
  //   allRent.map((item:any) =>  child.replace(...item.children))
  //   setTotalData(child)
  // },[allRent])
  useEffect(() => {
    setTimeout(() => {
      setItemAdded({ status: false, message: "" });
    }, 1500);
  }, [itemAdded.status]);


  return (
    <div className="container page_two_content">
      {searchData.length === totalData.length ? (
        <>
          {open === true && (
            <ShowModal
              setItemAdded={setItemAdded}
              edit={false}
              open={[open, setOpen]}
              item={items}
            />
          )}

          <div style={{maxWidth:"800px!important",margin:"auto"}}>
            <h4 className="recyclabels_title"></h4>
            <div className="alert alert-primary" role="alert" style={{maxWidth:"800px!important",margin:"auto"}}>
              <b>Notes</b>
              <ul>
                <li>The prices may vary with fluctuation in the scrap market.</li>
                <li>Prices may be different for bulk pickups. Call us at <a href="tel:+918595358613">+91-8595358613</a> to get a quote for bulk pickup.</li>
              </ul>

            </div>
          </div>


          {status === "success" &&
            data.data.map((block: any) => (
              <div key={block.id}>
                <div>
                  {JSON.stringify(block.children) !== "[]" && (
                    <h4 className="recyclables local_rate_title">{block.title}</h4>
                  )}

                  <div className="blockTwoPage style-2 web_view_item_rate">
                    {block.children.map((item: any) => (
                      <div key={item.id} className='box_outside web_box_outside'>
                        <div
                         
                          key={item.id}
                          className="block_box local_rate_block_box web_local_rate_block_box"
                        >
                            <img
                            className='rate_image'
                              src={`https://swapeco.com/${item.img}`}
                              alt={"icon"}
                            />
                            <p className='rate_price'>
                              RS {item.rate}/
                              {item.m_unit === "null" ? "KG" : item.m_unit}
                            </p>
                        </div>
                        <p className='local_specific_rate_title'>{item.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className='local_rate_block_box_outside_box'>
          {open === true && (
            <ShowModal
              setItemAdded={setItemAdded}
              edit={false}
              open={[open, setOpen]}
              item={items}
            />
          )}
          {searchData &&
            searchData.map((item: any) => (
              <div key={item.id} className='box_outside web_box_outside'>
              <div
               
                key={item.id}
                className="block_box local_rate_block_box web_local_rate_block_box"
              >
                  <img
                  className='rate_image'
                    src={`https://swapeco.com/${item.img}`}
                    alt={"icon"}
                  />
                  <p className='rate_price'>
                    RS {item.rate}/
                    {item.m_unit === "null" ? "KG" : item.m_unit}
                  </p>
              </div>
              <p className='local_specific_rate_title'>{item.title}</p>
            </div>
            ))}
          {searchData && searchData.length === 0 && (
            <p className="text-center w-100">No Search Found!</p>
          )}
        </div>
      )}
    
    </div>
  );
};

export default LocalRateItem;
