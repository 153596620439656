import React, { useState, useContext, useEffect } from "react";
import { UserLocation } from "../../App";
import { GoogleMap } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { GOOGLE_MAP } from "Config";
import Config from "Config";

const MapWithAMarker = () => {
  const [targetLocation, setTargetLocation] = useContext(UserLocation);
  const [myMap, setMyMap] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const latNumber = targetLocation && targetLocation.lat;
    const lngNumber = targetLocation && targetLocation.lng;

    if (typeof latNumber === "number") {
      setCenter({ lat: +latNumber, lng: +lngNumber });
    }
  }, [targetLocation]);

  const handleChange = () => {
    Geocode.setApiKey(GOOGLE_MAP.GOOGLE_MAP_KEY);
    Geocode.setLanguage("en");
    Geocode.fromLatLng(
      myMap && myMap.state.map.center.lat(),
      myMap && myMap.state.map.center.lng()
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log("Formatted Address:", address);
        let city, state, country, pincode;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                pincode = response.results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }
        setTargetLocation({
          ...targetLocation,
          line1: address,
          lat: myMap.state.map.center.lat(),
          lng: myMap.state.map.center.lng(),
          city: city,
          state: state,
          country: country,
          pincode: pincode,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const targetLat = targetLocation && targetLocation.lat;
  return typeof targetLat !== "number" ? null : (
    <GoogleMap
      mapContainerStyle={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      zoom={15}
      center={center}
      ref={(map) => targetLocation && setMyMap(map)}
      onDragEnd={() => setTimeout(handleChange)}
    >
      <div className="marker-container">
        <div className="marker_div">

      <img
            className="marker_pic"
            src={Config.EXTERNAL_IMAGES.ICONS.MAP_PIN}
            alt=""
          />
        </div>
        {/* <div className="pulse-box"> */}
        <div className="pulse-css">
         
        </div>
        {/* </div> */}
      </div>
    </GoogleMap>
  );
};

export default MapWithAMarker;
