import React, { useContext, useEffect, useState } from "react";
import "./EditProfile.css";
import "../EditCard/EditCard.css";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,

  IonRow,
  IonToast,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import {  useHistory } from "react-router-dom";
import Title from "./../Title";
import { UserProfile } from "./../../App";
import { useForm } from "react-hook-form";
import ApiRequest from "../../Services/Api.Service";
import useLocalStorage from "../../Services/useLocalStorage";
import { userEditProfile, userTabClick } from "../../Events";
interface ContainerProps {
  Header: boolean;
}

const EditProfile: React.FC<ContainerProps> = (props) => {
  const { register, handleSubmit } = useForm();
  const [profile, setProfile] = useContext<any>(UserProfile);
  const [showToast1, setShowToast1] = useState({ status: false, message: "" });
  const [loggedInUser, setLoggedInUser] = useLocalStorage<any>(
    "LoginUser",
    ""
  );
  const history = useHistory();
  const [loadingSpin, setLoadingSpin] = useState(false);

  const onSubmit = (data: any) => {
    if (data.name === loggedInUser.name && data.email === loggedInUser.email) {
      setShowToast1({ status: true, message: "Changed and Try Again!" });
    } else {
      setLoadingSpin(true); 
      ApiRequest("PUT", "/profile/", {
        isAuthenticated: false,
        token: loggedInUser.token,
        body: data,
      })
        .then((res: any) => {
          if (res.success === true) {
            setLoggedInUser({ ...loggedInUser, ...data });
            setProfile({ ...profile, ...data });
            setShowToast1({
              status: true,
              message: "Profile Updated Successfully",
            });
            userEditProfile("success");
            setTimeout(() => {
              history.push("/profile");
            }, 1800);
            setLoadingSpin(false);
          } else {
            userEditProfile("Failed");
            setShowToast1({
              status: true,
              message: "Profile Not Updated.Try Again!",
            });
            setLoadingSpin(false);
          }
        })
        .catch((err: any) => {
          setLoadingSpin(false);
          setShowToast1({ status: true, message: err });
        });
    }
    // if(data.name === loggedInUser.name ){
    //
    // }else{
    //
    // }
  };

  useEffect(() => {
    userTabClick(window.location.pathname);
  }, []);

  return (
    <>
      <IonToast
        isOpen={showToast1.status}
        onDidDismiss={() => setShowToast1({ status: false, message: "" })}
        message={showToast1.message}
        duration={700}
      />

      <IonHeader className="d-flex-pickupdetails">
        <img
          onClick={() => history.goBack()}
          src="https://ik.imagekit.io/y8s2vhk66ef/Vector_705__Stroke__wRtnyiesl.png"
          alt=""
        />
        <p onClick={() => history.goBack()}>Back</p>
      </IonHeader>

      <IonContent>
        <Title title="Editing Profile" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid style={{padding:"2 0px"}}>
            <IonRow>
              <IonCol>
                <div className="input_form">
                  <p className="input_title">Your Name</p>
                  <input
                    ref={register}
                    placeholder="Enter your Name"
                    name="name"
                    defaultValue={
                      (loggedInUser && loggedInUser.name) ||
                      (profile && profile.name)
                    }
                    type="text"
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="input_form">
                  <p className="input_title">Your Email</p>
                  <input
                    ref={register}
                    placeholder="Enter your Email"
                    name="email"
                    defaultValue={
                      (loggedInUser && loggedInUser.email) ||
                      (profile && profile.email) || ""
                    }
                    type="email"
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="bottom_Btn edit_profile_btn"
            type="submit"
            color="success"
            expand="full"
          >
            {loadingSpin ? (
              <IonSpinner name="lines" />
            ) : (
              <input type="submit" value="submit" className="submitBtn" />
            )}
          </IonButton>
        </form>
      </IonContent>
    </>
  );
};

export default EditProfile;
